import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};

const companyApi = {
    GetCompanyData: async () => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/show`,
            headers,
        })
    },

    GetCompanyDataById: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/show/${id}`,
            headers,
        })
    },

    GetCompanySubscriptions: async (id) =>{
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/subscriptions`,
            headers,
        })
    },

    GetCompanyUsersById: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/users`,
            headers,
        })
    },

    CreateCompanyData: async (data) => {
        const formData = new FormData();
        formData.append("logo", data.logo);
        formData.append("logoBgColor", data.logoBgColor);
        formData.append("name", data.name);
        formData.append("address", data.address);
        formData.append("city", data.city);
        formData.append("contactEmail", data.contactEmail);
        formData.append("contactFirstName", data.contactFirstName);
        formData.append("contactLastName", data.contactLastName);
        formData.append("contactPhone", data.contactPhone);
        formData.append("postalCode", data.postalCode);
        formData.append("recordStatus", data.recordStatus);
        formData.append("state", data.state);

        return await apiInstance({
            method: 'post',
            url: `${protectedResources.apiCompany.endpoint}/create`,
            headers: {
                "Content-Type": "multipart/form-data; boundary=AaB03x" +
                    "--AaB03x" +
                    "Content-Disposition: file" +
                    "Content-Type: png" +
                    "Content-Transfer-Encoding: binary" +
                    "...data... " +
                    "--AaB03x--",
                "Accept": "application/json",
                "type": "formData"
            },
            data: formData
        })
    },

    UpdateCompanyData: async (data) => {
        let file = data.logo;

        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("logo", file);
        formData.append("logoBgColor", data.logoBgColor);
        formData.append("name", data.name);
        formData.append("address", data.address);
        formData.append("city", data.city);
        formData.append("contactEmail", data.contactEmail);
        formData.append("contactFirstName", data.contactFirstName);
        formData.append("contactLastName", data.contactLastName);
        formData.append("contactPhone", data.contactPhone);
        formData.append("postalCode", data.postalCode);
        formData.append("recordStatus", data.recordStatus);
        formData.append("state", data.state);

        return await apiInstance({
            method: 'put',
            url: `${protectedResources.apiCompany.endpoint}/update`,
            headers: {
                "Content-Type": "multipart/form-data; boundary=AaB03x" +
                    "--AaB03x" +
                    "Content-Disposition: file" +
                    "Content-Type: png" +
                    "Content-Transfer-Encoding: binary" +
                    "...data... " +
                    "--AaB03x--",
                "Accept": "application/json",
                "type": "formData"
            },
            data: formData

        })
    },

    UpdateCompanySubscription: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiCompany.endpoint}/update-subscription`,
            headers,
            data,
        });
    },

    GetCompanyLogo: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/logo/${id}`,
            headers,
        })
    },

};

export default companyApi;
