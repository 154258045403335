import React, { useContext, useEffect, useState } from "react";
import api from "../../services/api/contentService";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import { ALERT_TYPE, CMS_CONTENT_FORMAT, MIME_TYPES } from '../../constants';
import { Card, Col, Row } from 'react-bootstrap';
import styles from './Home.module.scss';
import { getFileExtension } from "../../utils/utils";
import PdfViewer from '../../components/ui/PdfViewer';
import { AlertContext } from '../../context/AlertContext';
import messages from '../../utils/helper/messages';

const ContentCard = ({ openFile, type, title, contentLink }) => {
    const { showAlert } = useContext(AlertContext);
    const [contentCardData, setContentCardData] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);

    const handleClick = (blog) => {
        if (blog) {
            const { id, format } = blog;
            const type = MIME_TYPES[CMS_CONTENT_FORMAT[format]];
            const content = { id, title, type };
            openFile(content);
        }
    };

    const renderContent = (file) => {
        const fileExt = getFileExtension(file.fileName);
        switch (fileExt) {
            case 'pdf':
                return (
                    <PdfViewer file={file.file} isPreview={true} />
                );
            case 'html':
                return (
                    <object
                        data={file.file}
                        type={MIME_TYPES[CMS_CONTENT_FORMAT[file.format]]}
                        className={`${styles.objectWrap} w-100`}
                    />
                );
            case 'jpg':
            case 'png':
            case 'gif':
            case 'svg':
                return (
                    <div className={`${styles.imageWrap}`}>
                        <img src={file.file} alt={file.title} />
                    </div>
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        if (isAppLoaded) {
            api.GetContentRecordByType(type).then((res) => {
                const content = res.data;
                if (content && content.length > 0) {
                    setContentCardData(content[0])
                }
            });
        }
    }, [isAppLoaded]);

    useEffect(() => {
        if (contentCardData?.id) {
            api.GetContentFile(contentCardData.id)
                .then((response) => {
                    const { data, error } = response;
                    if (error) {
                        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
                        setContentCardData({ ...contentCardData, file: null });
                    } else {
                        setContentCardData({ ...contentCardData, file: data.contentFile });
                    }
                }).catch(error => {
                    showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
                });
        }
    }, [contentCardData?.id]);

    return (
        contentCardData &&
        <div className={`${styles.imgWrapper} cursor-pointer`} onClick={() => handleClick(contentCardData)}>
            <Row className="flex-grow-1">
                <Col md={4}>
                    <Card.Text className={`${styles.graphText} ${styles.cardText}`}>{contentCardData.title}</Card.Text>
                    <div className={`mb-1 ${styles.blogTitle}`} onClick={() => handleClick(contentCardData)}>
                        <span className="text-decoration-none">{contentLink} </span>
                    </div>
                </Col>
                <Col md={8}>
                    {contentCardData.file && renderContent(contentCardData)}
                </Col>
            </Row>
        </div>
    );
}

export default ContentCard;
