import React from "react";
import withAuth from '../../../components/hoc/withAuth';
import Placeholder from '../../../components/ui/Placeholder';

const RiskMonitoringGraphic = () => {
    return (
        <Placeholder title='Risk Monitor' type="graphic" />
    );
};

export default withAuth(RiskMonitoringGraphic);
