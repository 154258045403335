import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import withAuth from "../../../../components/hoc/withAuth";
import DataGrid from "../../../../components/DataGrid";
import ActionBar from "../../../../components/ui/ActionBar";
import GridButton from "../../../../components/ui/GridButton";
import Icon from "../../../../components/ui/Icon";
import {
    isBeforeToday,
    isSameOrBeforeToday,
    pickProps,
    toDate,
    toTimezoneFormat,
    toDateTime,
    omitProps,
    dayOfWeek,
    daysLeftForFutureDate,
    getFullName
} from '../../../../utils/utils';
import EditMeetingModal from "./EditMeetingModal";
import AddLocationModal from "./AddLocationModal";
import styles from './MeetingDetails.module.scss';
import MeetingAttendanceModal from "./MeetingAttendanceModal";
import PreMeetingResponseModal from "./PreMeetingResponseModal";
import DocumentationModal from "./DocumentationModal";
import {
    archiveMeetingLocation,
    getAdvisoryGroupMeetingDetails,
    getAdvisoryGroupMeetingLocations,
    getAdvisoryGroupMeetingMemberData,
    resetMeetingMemberComments,
    getMeetingLocationSupportStaffDetailsByAgId,
} from '../../../../ducks/advisoryGroup';
import { useDispatch, useSelector } from "react-redux";
import { AppLoadContext } from "../../../../components/ui/AppLoadContext";
import {
    AG_MEETING_ATTENDANCE_STATUS_POST, AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE,
    AG_MEETING_ATTENDANCE_STATUS_PRE, AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE,
    AG_MEETING_STATUS, AG_MEETING_STATUS_INVERSE,
    ALERT_TYPE,
    PARKING_STATUS,
    PROGRAM_MEETING_TYPE,
    RECORD_STATUS_ABBR, TIMEZONES, TRANSPORTATION_MODE,
} from '../../../../constants';
import Loader from "../../../../components/ui/Loader";
import messages from "../../../../utils/helper/messages";
import ConfirmModal from "../../../../components/ui/ConfirmModal";
import { AlertContext } from "../../../../context/AlertContext";
import OverflowText from "../../../../components/ui/OverflowTooltip";
import EditMemberStatusModal from "./EditMemberStatusModal";
import CaregiverAttendanceModal from "./CaregiverAttendanceModal";
import SupportStaffDetailsModal from "./SupportStaffDetailsModal";
import AssignSupportStaffModal from './AssignSupportStaffModal';
import MeetingSupportStaff from './MeetingSupportStaff';
import CancelMeetingModal from "./CancelMeetingModal";
import MeetingNonMembers from './MeetingNonMembers';

const MeetingDetails = () => {
    const { meetingId, agId } = useParams();
    const { showAlert } = useContext(AlertContext);
    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.user.currentUser);
    const meetingDetails = useSelector(state => state.advisoryGroup.currentMeetingDetail);
    const meetingLocationData = useSelector(state => state.advisoryGroup.meetingLocations);
    const memberData = useSelector(state => state.advisoryGroup.meetingMembers);

    const [isPastMeeting, setIsPastMeeting] = useState(false);
    const [isTodayOrPastMeeting, setIsTodayOrPastMeeting] = useState(false);

    // Edit Meeting
    const [editModal, setEditModal] = useState(false);
    const [meetingData, setMeetingData] = useState(null);

    // Add Location
    const [addLocationModal, setAddLocationModal] = useState(false);
    const [locationId, setLocationId] = useState(null);

    // Meeting Attendance and RSVP
    const [attendanceModal, setAttendanceModal] = useState(false);
    const [preMeetingResponseModal, setPreMeetingResponseModal] = useState(false);
    const [meetingResponseData, setMeetingResponseData] = useState(null);

    // Documentation
    const [documentationModal, setDocumentationModal] = useState(false);

    // Support Staff
    const [supportStaffModal, setSupportStaffModal] = useState(false);

    // Archive location
    const [confirmModal, setConfirmModal] = useState(false);

    // Edit Member
    const [memberModal, setMemberModal] = useState(false);
    const [memberStatusData, setMemberStatusData] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    // Host Details
    const [supportStaffDetailsModal, setSupportStaffDetailsModal] = useState(false);
    const [supportStaffDetails, setSupportStaffDetails] = useState(null);

    // Caregiver Attendance
    const [openCaregiverAttendanceModal, setOpenCaregiverAttendanceModal] = useState(false);
    const [currentRowData, setCurrentRowData] = useState(null);

    // Cancel Meeting
    const [openCancelMeetingModal, setOpenCancelMeetingModal] = useState(false);

    const checkMeetingStatus = [AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented]

    // Grid columns
    const memberStatusColumns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
        },
        {
            id: 'recordModified',
            header: 'Last Update',
            accessorKey: 'Last Update',
            accessorFn: (row) => (new Date(row.recordModified)),
            cell: ({ row }) => `${toDateTime(row.original.recordModified)}`,
            sortType: 'date',
            sortingFn: 'datetime'
        },
        {
            id: 'preMeetingResponse',
            header: 'Pre-Meeting Response',
            accessorKey: 'preMeetingResponse',
            cell: ({ row }) => AG_MEETING_ATTENDANCE_STATUS_PRE[row.original.preMeetingResponse?.trim()],
        },
        {
            id: 'meetingParticipation',
            header: 'Meeting Participation',
            accessorKey: 'meetingParticipation',
            cell: ({ row }) => omitProps(AG_MEETING_ATTENDANCE_STATUS_POST, AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE.FutureMeeting)[row.original.meetingParticipation?.trim()],
        },
        {
            id: 'locationName',
            header: 'Location',
            accessorKey: 'locationName',
        },
        {
            id: 'transportation',
            header: 'Transport',
            accessorKey: 'transportation',
            cell: ({ row }) => TRANSPORTATION_MODE[row.original.transportation]
        },
        {
            id: 'accommodationsRequested',
            header: 'Accommodation Requests',
            accessorKey: 'accommodationsRequested',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.accommodationsRequested}
                </OverflowText>
            )
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Caregiver Attendance" disabled={checkMeetingStatus.includes(meetingDetails?.status) || row.original.preMeetingResponse !== AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.Confirmed}
                        onClick={() => handleCaregiverAttendanceModalOpen(row.original)}>
                        <Icon icon="caregiver-attendance" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit" disabled={checkMeetingStatus.includes(meetingDetails?.status)}
                        onClick={() => handleMemberModalOpen(row.original)}>
                        <Icon icon="edit" size={14} />
                    </GridButton>
                </>

            )
        }
    ]
    const locationColumns = [
        {
            id: 'city',
            header: 'Location City',
            accessorKey: 'city',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.city}
                </OverflowText>
            ),
        },
        {
            id: 'name',
            header: 'Location Name',
            accessorKey: 'name',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.name}
                </OverflowText>
            ),
        },
        {
            id: 'contactName',
            header: 'Location Contact',
            accessorKey: 'contactName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.contactName}
                </OverflowText>
            ),
        },
        {
            id: 'parking',
            header: 'Location Parking',
            accessorKey: 'parking',
            cell: ({ row }) => (PARKING_STATUS[row.original.parking])
        },
        {
            id: 'hostName',
            header: 'Host Name',
            accessorKey: 'hostName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {getFullName(row.original.firstName, row.original.lastName)}
                </OverflowText>
            ),
        },
        {
            id: 'comment',
            header: 'Comments',
            accessorKey: 'comment',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.comment}
                </OverflowText>
            ),
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 120,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Host Details" disabled={checkMeetingStatus.includes(meetingDetails?.status) || isPastMeeting}
                        onClick={() => handleSupportStaffDetailsModal(row.original)}>
                        <Icon icon="meeting-host" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title={checkMeetingStatus.includes(meetingDetails?.status) ? 'View' : 'Edit'} disabled={isPastMeeting}
                        onClick={() => handleLocationEdit(row.original)}>
                        <Icon icon={checkMeetingStatus.includes(meetingDetails?.status) ? 'view' : 'edit'} size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Cancel" disabled={checkMeetingStatus.includes(meetingDetails?.status) || isPastMeeting}
                        onClick={() => handleLocationCancel(row.original.id)}>
                        <Icon icon="canceled" size={14} />
                    </GridButton>
                </>
            )
        }
    ];

    const AdditionalInfo = ({ label, value, wrapperStyle }) => {
        return (
            <div className={`d-flex align-items-center py-1 ${wrapperStyle ? wrapperStyle : ''}`}>
                <div className={`${styles.additionalDetailsSubtitle} flex-grow-1 text-end align-content-center pe-3`}>
                    {label}
                </div>
                <div className={`${styles.meetingDetails} text-center text-black bg-white flex-shrink-0`}>
                    {value}
                </div>
            </div>
        );
    };

    const MeetingInfo = ({ info }) => {
        return (
            <div className="bg-secondary text-white p-4 mb-4">
                <h3 className={`${styles.title} fw-bold mb-4`}>
                    {info.agShortName} - {info.title}
                </h3>
                <Row className="align-items-start align-items-xl-stretch">
                    <Col xl={3} lg={4} xxl="auto">
                        <div className="pe-2 pe-xxl-5">
                            <AdditionalInfo label="Day of Meeting" value={dayOfWeek(info.meetingDate)} wrapperStyle="mb-4" />
                            <AdditionalInfo label="Days Until Meeting" value={isPastMeeting ? 0 : daysLeftForFutureDate(info.meetingDate)} wrapperStyle="mb-4" />
                            <AdditionalInfo label="Confirmed Members" value={info.confirmedCount} />
                        </div>
                    </Col>
                    <Col>
                        <Row className="justify-content-between">
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Status
                                </h4>
                                <p>{AG_MEETING_STATUS[info.status]}</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Owner
                                </h4>
                                <p>{info.ownerName}</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Type
                                </h4>
                                <p>{PROGRAM_MEETING_TYPE[info.type]}</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Created
                                </h4>
                                <p>{toDate(info.recordCreated)}</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Date
                                </h4>
                                <p>{toTimezoneFormat(info.meetingDate, 'MM/DD/yyyy', TIMEZONES[info.meetingTimeZone])}</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Time
                                </h4>
                                <p>{toTimezoneFormat(info.meetingDate, 'hh:mm:ss A', TIMEZONES[info.meetingTimeZone])}</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Meeting Time Zone
                                </h4>
                                <p>{toTimezoneFormat(info.meetingDate, 'zz', TIMEZONES[info.meetingTimeZone])} ({TIMEZONES[info.meetingTimeZone]})</p>
                            </Col>
                            <Col xl={3} md={6} className={styles.details}>
                                <h4 className={`${styles.subTitle} fw-bold mb-0`}>
                                    Last Modified
                                </h4>
                                <p>{toDate(info.recordModified)}</p>
                            </Col>
                        </Row>
                        <div className={`${styles.meetingActions} d-flex flex-wrap gap-2 mt-1`}>
                            <Button
                                variant="primary"
                                disabled={
                                    checkMeetingStatus.includes(info.status) ||
                                    (isPastMeeting &&
                                    info.status ===
                                    AG_MEETING_STATUS_INVERSE.Completed)
                                }
                                onClick={() => setEditModal(true)}>
                                Edit Meeting
                            </Button>
                            <Button
                                variant="primary"
                                disabled={checkMeetingStatus.includes(info.status) ||
                                    (isPastMeeting ||
                                    info.status ===
                                    AG_MEETING_STATUS_INVERSE.Completed)
                                }
                                onClick={() => handleAddLocationModal()}>
                                Add Location
                            </Button>
                            <Button
                                variant="primary"
                                disabled={checkMeetingStatus.includes(info.status) || isTodayOrPastMeeting}
                                onClick={() =>
                                    setPreMeetingResponseModal(true)
                                }>
                                Meeting RSVP
                            </Button>
                            <Button
                                variant="primary"
                                disabled={checkMeetingStatus.includes(info.status) || !isTodayOrPastMeeting}
                                onClick={() => setAttendanceModal(true)}>
                                Meeting Attendance
                            </Button>
                            <Button
                                variant="primary"
                                disabled={checkMeetingStatus.includes(info.status)}
                                onClick={() => setDocumentationModal(true)}>
                                Documentation
                            </Button>
                            <Button
                                variant="primary"
                                disabled={[AG_MEETING_STATUS_INVERSE.Completed, AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented].includes(info.status) || isPastMeeting}
                                onClick={() => setSupportStaffModal(true)}>
                                Support Staff
                            </Button>
                            <Button
                                variant="primary"
                                disabled={[AG_MEETING_STATUS_INVERSE.Completed, AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented].includes(info.status)}
                                onClick={() => setOpenCancelMeetingModal(true)}>
                                Cancel Meeting
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    const handleAddLocationModal = () => {
        setAddLocationModal(true);
        dispatch(getMeetingLocationSupportStaffDetailsByAgId(meetingDetails.companyId, meetingDetails.aGId));
    }

    const handleEditMeetingModalClose = () => {
        setEditModal(false);
    }

    const handleAddLocationModalClose = () => {
        setLocationId(null);
        setAddLocationModal(false);
    }

    const handleSupportStaffDetailsModalClose = () => {
        setSupportStaffDetailsModal(false);
    }

    const handleAttendanceModalClose = () => {
        setAttendanceModal(false);
        dispatch(getAdvisoryGroupMeetingMemberData(meetingDetails.aGId, meetingDetails.id));
    }

    const handlePreMeetingResponseModalClose = () => {
        setPreMeetingResponseModal(false);
        dispatch(getAdvisoryGroupMeetingMemberData(meetingDetails.aGId, meetingDetails.id));
    }

    const handleDocumentationModalClose = () => {
        setDocumentationModal(false);
    }

    const handleSupportStaffModalClose = () => {
        setSupportStaffModal(false);
    }

    const handleCaregiverAttendanceModalOpen = (data) => {
        setOpenCaregiverAttendanceModal(true);
        setCurrentRowData({ ...data });
    }

    const handleMemberModalOpen = (data) => {
        setMemberModal(true);
        const memberData = pickProps(data, ['accommodationsRequested', 'firstName', 'lastName', 'locationId', 'recordModified', 'transportation']);
        setSelectedMemberId(data.agMemberId);
        setSelectedId(data.id);
        setMemberStatusData({
            ...memberData,
            comment: '',
            locationId: data.locationId ? data.locationId?.toString() : null,
            preMeetingResponse: data.preMeetingResponse ? data.preMeetingResponse?.trim() : AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.NoResponse,
            meetingParticipation: data.meetingParticipation ? data.meetingParticipation?.trim() : AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE.FutureMeeting
        });
    }

    const handleCaregiverAttendanceModalClose = () => {
        dispatch(getAdvisoryGroupMeetingMemberData(agId, meetingId));
        setOpenCaregiverAttendanceModal(false);
    };

    const handleMemberModalClose = () => {
        setMemberStatusData(null);
        setSelectedId(null);
        setSelectedMemberId(null);
        setMemberModal(false);
        setSupportStaffDetails(null);
        dispatch(getAdvisoryGroupMeetingMemberData(meetingDetails.aGId, meetingDetails.id));
        dispatch(resetMeetingMemberComments());
        dispatch(getAdvisoryGroupMeetingDetails(meetingId));
    }

    const handleLocationEdit = (data) => {
        setLocationId(data.id);
        setAddLocationModal(true);
        dispatch(getMeetingLocationSupportStaffDetailsByAgId(meetingDetails.companyId, meetingDetails.aGId));
        const locationData = pickProps(data, ['id', 'firstName', 'lastName', 'staffId']);
        const hostName = data?.firstName ? `${locationData?.firstName} ${locationData?.lastName}` : null;
        setSupportStaffDetails({ ...locationData, hostName: hostName });
    }

    const handleSupportStaffDetailsModal = (data) => {
        dispatch(getMeetingLocationSupportStaffDetailsByAgId(meetingDetails.companyId, meetingDetails.aGId));
        setSupportStaffDetailsModal(true);
        const locationData = pickProps(data, ['id', 'firstName', 'lastName', 'staffId']);
        const hostName = data?.firstName ? `${locationData?.firstName} ${locationData?.lastName}` : null;
        setSupportStaffDetails({ ...locationData, hostName: hostName });
    }

    const handleLocationArchive = (id) => {
        dispatch(archiveMeetingLocation({
            id,
            status: RECORD_STATUS_ABBR.Archived
        }, () => {
            showAlert(messages.success.archiveMeetingLocation, ALERT_TYPE.SUCCESS);
        }));
    }

    const handleLocationCancel = (id) => {
        setLocationId(id);
        setConfirmModal(true);
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setLocationId(null);
    }

    const handleConfirm = () => {
        handleLocationArchive(locationId);
        handleConfirmClose();
    }

    const handleCancelMeetingModalClose = () => {
        setOpenCancelMeetingModal(false);
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupMeetingDetails(meetingId));
            dispatch(getAdvisoryGroupMeetingLocations(meetingId));
            dispatch(getAdvisoryGroupMeetingMemberData(agId, meetingId));
        }
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        if (memberData) {
            const meetingData = memberData.map((member) =>
            ({
                id: member.agMemberId,
                attendanceLogId: member.id,
                name: `${member.firstName} ${member.lastName}`,
                meetingParticipation: member.meetingParticipation ? member.meetingParticipation.trim() : AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE.FutureMeeting,
                preMeetingResponse: member.preMeetingResponse ? member.preMeetingResponse.trim() : AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.NoResponse,
                locationId: member.locationId ?? null,
                accommodationsRequested: member.accommodationsRequested ?? null,
                recordModified: toDateTime(member.recordModified),
            })
            );
            setMeetingResponseData(meetingData);
        }
    }, [memberData]);

    useEffect(() => {
        if (meetingDetails) {
            setMeetingData({
                ...meetingDetails,
                ownerName: `${meetingDetails.ownerFirstName} ${meetingDetails.ownerLastName}`,
                meetingTime: toTimezoneFormat(
                    meetingDetails.meetingDate,
                    'HH:mm',
                    TIMEZONES[meetingDetails.meetingTimeZone]
                ),
            });
            setIsPastMeeting(isBeforeToday(meetingDetails.meetingDate));
            setIsTodayOrPastMeeting(
                isSameOrBeforeToday(meetingDetails.meetingDate)
            );
        }
    }, [meetingDetails]);

    return (
        <div>
            {meetingData && <MeetingInfo info={meetingData} />}

            <Row className="mb-4 g-xl-40">
                <Col md={6}>
                    <ActionBar title="Current Member Status" />
                    {memberData === null ? (
                        <Loader />
                    ) : (
                        <DataGrid
                            data={memberData}
                            columns={memberStatusColumns}
                            enableFilters={false}
                        />
                    )}
                </Col>
                <Col md={6}>
                    <MeetingNonMembers
                        meeting={{
                            id: meetingId,
                            agId: agId,
                            isTodayOrPast: isTodayOrPastMeeting,
                            isPast: isPastMeeting,
                            status: meetingDetails?.status,
                        }}
                        currentUser={currentUser}
                    />
                </Col>
            </Row>

            <div className="mb-4">
                <ActionBar title="Meeting Location Data" />
                {meetingLocationData === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        data={meetingLocationData}
                        columns={locationColumns}
                        enableFilters={false}
                    />
                )}
            </div>

            <MeetingSupportStaff
                meeting={{
                    id: meetingId,
                    agId: agId,
                    isPast: isPastMeeting,
                    isTodayOrPast: isTodayOrPastMeeting,
                    status: meetingDetails?.status,
                    companyId: meetingDetails?.companyId
                }}
                locations={meetingLocationData}
            />

            {editModal && (
                <EditMeetingModal
                    show={editModal}
                    onCancel={handleEditMeetingModalClose}
                    onUpdate={() =>
                        dispatch(getAdvisoryGroupMeetingDetails(meetingId))
                    }
                    data={{
                        ...meetingData,
                        meetingDate: toTimezoneFormat(
                            meetingDetails.meetingDate,
                            'MM/DD/yyyy',
                            TIMEZONES[meetingDetails.meetingTimeZone]
                        ),
                    }}
                />
            )}

            {supportStaffDetailsModal && (
                <SupportStaffDetailsModal
                    show={supportStaffDetailsModal}
                    onCancel={handleSupportStaffDetailsModalClose}
                    data={supportStaffDetails}
                    meetingId={meetingId}
                />
            )}

            {addLocationModal && (
                <AddLocationModal
                    show={addLocationModal}
                    id={locationId}
                    agInfo={{
                        aGId: meetingDetails.aGId,
                        companyId: currentUser?.companyId,
                        meetingId: meetingDetails.id,
                    }}
                    onAdd={() =>
                        dispatch(getAdvisoryGroupMeetingLocations(meetingId))
                    }
                    onCancel={handleAddLocationModalClose}
                    hostData={supportStaffDetails}
                    viewOnly={checkMeetingStatus.includes(meetingDetails?.status)}
                />
            )}

            {attendanceModal && (
                <MeetingAttendanceModal
                    show={attendanceModal}
                    info={{
                        agId: meetingDetails.aGId,
                        companyId: currentUser?.companyId,
                        meetingId: meetingDetails.id,
                    }}
                    onCancel={handleAttendanceModalClose}
                    data={meetingResponseData}
                />
            )}

            {preMeetingResponseModal && (
                <PreMeetingResponseModal
                    show={preMeetingResponseModal}
                    info={{
                        agId: meetingDetails.aGId,
                        companyId: currentUser?.companyId,
                        meetingId: meetingDetails.id,
                    }}
                    onCancel={handlePreMeetingResponseModalClose}
                    data={meetingResponseData}
                />
            )}

            {documentationModal && (
                <DocumentationModal
                    show={documentationModal}
                    onCancel={handleDocumentationModalClose}
                    meetingId={meetingDetails.id}
                    currentUserId={currentUser?.id}
                    onUpdate={() =>
                        dispatch(getAdvisoryGroupMeetingDetails(meetingId))
                    }
                />
            )}

            {supportStaffModal && (
                <AssignSupportStaffModal
                    show={supportStaffModal}
                    info={{
                        agId: agId,
                        meetingId: meetingId,
                    }}
                    locations={meetingLocationData}
                    onCancel={handleSupportStaffModalClose}
                />
            )}

            {memberModal && (
                <EditMemberStatusModal
                    show={memberModal}
                    isPastMeeting={isPastMeeting}
                    isTodayOrPastMeeting={isTodayOrPastMeeting}
                    locations={meetingLocationData}
                    info={{
                        id: selectedId,
                        agId: meetingDetails?.aGId,
                        companyId: currentUser?.companyId,
                        meetingId: meetingId,
                        agMemberId: selectedMemberId,
                        userId: currentUser?.id,
                        meetingStatus: meetingDetails?.status,
                    }}
                    data={memberStatusData}
                    onCancel={handleMemberModalClose}
                    onComment={handleMemberModalClose}
                />
            )}

            {openCancelMeetingModal && (
                <CancelMeetingModal
                    show={openCancelMeetingModal}
                    onCancel={handleCancelMeetingModalClose}
                    meetingId={meetingId}
                />
            )}

            {openCaregiverAttendanceModal && (
                <CaregiverAttendanceModal
                    data={currentRowData}
                    show={openCaregiverAttendanceModal}
                    onCancel={handleCaregiverAttendanceModalClose}
                />
            )}

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.archiveMeetingLocation}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </div>
    );
}

export default withAuth(MeetingDetails);
