import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Modal } from 'react-bootstrap';
import LoadingButton from '../../../components/ui/LoadingButton';
import { checkValuesEqual, pickProps } from '../../../utils/utils';
import { ALERT_TYPE, APP_ICONS, APPLICATION_SUBSCRIPTION, SUBSCRIPTION, SUBSCRIPTION_LABEL } from '../../../constants';
import messages from '../../../utils/helper/messages';
import { AlertContext } from '../../../context/AlertContext';
import { updateCompanySubscription } from '../../../ducks/company';
import Icon from '../../../components/ui/Icon';

const SubscriptionModal = ({ show, data = null, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [initialSubscriptions, setInitialSubscriptions] = useState([]);
    const [companyId, setCompanyId] = useState(null);

    const handleInputChange = (event, subscription) => {
        if (event.target.checked) {
            if (!subscriptions.includes(subscription)) {
                setSubscriptions(prev => prev.concat(subscription));
            }
        } else {
            if (subscriptions.includes(subscription)) {
                setSubscriptions(prev => prev.filter(sub => sub !== subscription));
            }
        }
    }

    const getSubscriptionValue = (app) => {
        return subscriptions.includes(app)
            ? APPLICATION_SUBSCRIPTION.Subscribed
            : APPLICATION_SUBSCRIPTION.NotSubscribed;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const submitData = {
            id: companyId,
            subscriptionAGM: getSubscriptionValue(SUBSCRIPTION.advisoryGroupManagement),
            subscriptionMM: getSubscriptionValue(SUBSCRIPTION.memberAnalytics),
            subscriptionQM: getSubscriptionValue(SUBSCRIPTION.qualityManagement),
            subscriptionPD: getSubscriptionValue(SUBSCRIPTION.planDesign),
            subscriptionMF: getSubscriptionValue(SUBSCRIPTION.marketAssessment),
        }
        dispatch(updateCompanySubscription(submitData, handleSuccess, handleError));
    }

    const handleSuccess = () => {
        setLoading(false);
        showAlert(messages.success.updateSubscription, ALERT_TYPE.SUCCESS);
        onCancel();
    }

    const handleError = (error) => {
        setLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const isDataUpdated = () => {
        return !(checkValuesEqual(subscriptions, initialSubscriptions))
    }

    useEffect(() => {
        if (data) {
           const subscriptions = pickProps(data, Object.values(SUBSCRIPTION));
           const subscriptionArr = Object.keys(subscriptions).filter(sub => subscriptions[sub] === APPLICATION_SUBSCRIPTION.Subscribed);
           setInitialSubscriptions(subscriptionArr);
           setSubscriptions(subscriptionArr);
           setCompanyId(data.id);
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Manage Subscriptions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-xl-2">
                    <Form
                        noValidate
                        onSubmit={handleSubmit}>
                        <div className="text-end mb-4">
                            <Button
                                variant="outline-primary"
                                type="button"
                                className="me-2"
                                onClick={onCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                disabled={loading || !isDataUpdated()}>
                                Submit
                            </LoadingButton>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="flex-grow-1 fw-bold pe-3">Company Subscriptions</div>
                        </div>
                        <div>
                            {Object.keys(SUBSCRIPTION).map((appKey) => {
                                return (
                                    <div className="d-flex mb-1 px-2 py-1 rounded-2 hover-highlight" key={appKey.toLowerCase()}>
                                        <Form.Label htmlFor={`sub_${appKey.toLowerCase()}`} className="flex-grow-1 d-flex align-items-center gap-2">
                                            <Icon
                                                icon={APP_ICONS[appKey]}
                                                size={18}
                                            />
                                            {SUBSCRIPTION_LABEL[appKey]}
                                        </Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            name={`sub_${appKey.toLowerCase()}`}
                                            id={`sub_${appKey.toLowerCase()}`}
                                            value="N"
                                            checked={subscriptions?.includes(SUBSCRIPTION[appKey]) ?? false}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    SUBSCRIPTION[appKey]
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SubscriptionModal;
