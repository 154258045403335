import {
    Route, createBrowserRouter,
    createRoutesFromElements,
    RouterProvider
} from "react-router-dom";

// Kitchen Sink
import CompanyReactTable from "./kitchen-sink/CompanyReactTable";
import NestedReactTable from "./kitchen-sink/NestedReactTable";
import GroupedReactTable from "./kitchen-sink/GroupedReactTable";

// Home
import Layout from "../components/ui/Layout";
import Loader from "../components/ui/Loader";
import Home from "./home/Home";
import { USER_ROLE } from "../constants";

// Common Routes
import ErrorRedirect from "./ErrorRedirect";
import SessionExpired from "./SessionExpired";
import SessionLogout from "./SessionLogout";
import NotFound from "./NotFound";
import PasswordChangedRedirect from "./PasswordChangedRedirect";

// Admin Views
import Admin from "./admin/Admin";
import UserManagement from "./admin/user/UserManagement";
import Company from "./admin/company/Company";
import ContentManagement from "./admin/cms/ContentManagement";
import ArchivedContent from "./admin/cms/ArchivedContent";
import EacParticipationResponses from "./admin/EacParticipationResponses";
import AddEACParticipationResponse from "./admin/AddEACParticipationResponse";

// Quality Management Views
import StarExplorerHome from "./starExplorer/home";
import StarExplorerNationalDashboard from "./starExplorer/nationalDashboard";
import QualityManagementHome from "./quality-management/Home";
import QualityManagementHospitalHome from "./quality-management/Hospital";
import StateOverview from "./starExplorer/stateOverview";
import StateDetail from "./starExplorer/stateDetail";
import ReportCard from "./starExplorer/reportCard";
import Mortality from "./starExplorer/mortality";
import Readmission from "./starExplorer/readmission";
import Safety from "./starExplorer/safety";
import PatientExp from "./starExplorer/patientExp";
import Timely from "./starExplorer/timely";
import MultiFacility from "./starExplorer/multiFacility";
import StarPlanner from "./starExplorer/StarPlanner";

// Retention Management Views
import MemberManagementHome from "./member-analytics/member-management/MemberManagementHome";
import CohortManagement from "./cohort-management/CohortManagement";
import MergeCohort from "./cohort-management/MergeCohort";
import ArchivedCohorts from "./cohort-management/ArchivedCohorts";
import EditCohort from "./cohort-management/EditCohort";
import MemberList from "./member-analytics/member-management/MemberList";
import CohortUploadOverview from "./cohort-management/CohortUploadOverview";
import PopulationCharacterization from "./member-analytics/population-characterization/PopulationCharacterization";
import RetentionRisk from "./member-analytics/retention-risk/RetentionRisk";
import RiskIdentification from "./member-analytics/retention-risk/RiskIdentification";
import RiskScore from "./member-analytics/retention-risk/RiskScore";
import RiskMonitoringGraphic from "./member-analytics/retention-risk/RiskMonitoringGraphic";
import RiskExploration from "./member-analytics/risk-explorer/RiskExploration";
import RiskExplorer from "./member-analytics/risk-explorer/RiskExplorer";
import RiskManager from "./member-analytics/retention-risk/RiskManager";
import RiskCoreDemographics from "./member-analytics/risk-explorer/CoreDemographics";
import RiskGeographicDispersion from "./member-analytics/risk-explorer/GeographicDispersion";
import RiskTechnologyLiteracy from "./member-analytics/risk-explorer/TechnologyLiteracy";
import RiskHealthAccess from "./member-analytics/risk-explorer/HealthAccess";
import RiskFinancialIndex from "./member-analytics/risk-explorer/FinancialIndex";
import RiskOtherInsights from "./member-analytics/risk-explorer/OtherInsights";
import ArchivedRetentionRiskCohorts from "./member-analytics/retention-risk/ArchivedRetentionRiskCohorts";
import CohortView from "./member-analytics/retention-risk/cohort-view/CohortView";
import CompanyView from "./member-analytics/retention-risk/company-view/CompanyView";

// Advisory Group Pages
import AdvisoryGroupHome from './advisoryGroup-management/Home';
import AdvisoryGroupAnalytics from './advisoryGroup-management/analytics/Analytics';
import AdvisoryGroupDocumentManagement from './advisoryGroup-management/DocumentManagement';
import AdvisoryGroupOperations from './advisoryGroup-management/operations/Operations';
import OperationDetails from './advisoryGroup-management/operations/operation-details/OperationDetails';
import MeetingDetails from "./advisoryGroup-management/operations/meeting-details/MeetingDetails";
import AdvisoryGroupProgramManagement from './advisoryGroup-management/program-management/ProgramManagement';
import ArchivedPrograms from './advisoryGroup-management/program-management/ArchivedPrograms';
import AdvisoryGroupRecruitment from './advisoryGroup-management/recruitment/Recruitment';
import RecruitingAnalytics from './advisoryGroup-management/recruitment/recruiting-analytics/RecruitingAnalytics';
import RecruitingAgView from './advisoryGroup-management/recruitment/recruiting-analytics/RecruitingAgView';
import RecruitingCompanyView from './advisoryGroup-management/recruitment/recruiting-analytics/RecruitingCompanyView';
import RecruitmentStatus from "./advisoryGroup-management/recruitment/RecruitmentStatus";
import AdvisoryGroupRegulatoryReporting from './advisoryGroup-management/RegulatoryReporting';
import SystemNotification from './admin/systemNotification/SystemNotification';
import MasterSchedule from './advisoryGroup-management/operations/MasterSchedule';
import SupportStaff from './advisoryGroup-management/operations/support-staff/SupportStaff';
import Representativeness from './advisoryGroup-management/analytics/Representativeness';
import HealthEquity from './advisoryGroup-management/analytics/HealthEquity';
import Assessment from './advisoryGroup-management/analytics/Assessment';
import AnalyticsPopulationCharacterization from "./advisoryGroup-management/analytics/PopulationCharacterization";
import ApplicationDetail from './advisoryGroup-management/recruitment/ApplicationDetail';
import PanelSelection from './advisoryGroup-management/recruitment/panel-selection/PanelSelection';
import RecruitingOutreach from './advisoryGroup-management/recruitment/RecruitingOutreach';
import PanelMaintenance from './advisoryGroup-management/recruitment/panel-maintenance/PanelMaintenance';

// Market Assessment Views
import MarketAssessmentHome from "./market-assessment/MarketAssessmentHome";

// Plan Design and Fit Views
import PlanDesignFitHome from "./plan-design-and-fit/PlanDesignFitHome";

// User Notifications
import UserNotifications from "./admin/systemNotification/UserNotifications";


const Pages = () => {
    const router = createBrowserRouter(

        createRoutesFromElements(
            <Route element={<Layout />}>

                <Route index element={<Home />} />

                {/* Admin Views */}
                <Route path="admin" handle={{ crumb: () => 'admin-center', roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.CLIENT_ADMIN] }} >
                    <Route index element={<Admin />} />
                    <Route path="user-management" handle={{ crumb: () => 'user-management' }} >
                        <Route index element={<UserManagement />} />
                    </Route>
                    <Route path="company-management" handle={{ crumb: () => 'company-management' }} >
                        <Route index element={<Company />} />
                    </Route>
                    <Route path="content-management" handle={{ crumb: () => 'content-management', roles: [USER_ROLE.SYSTEM_ADMIN] }} >
                        <Route index element={<ContentManagement />} />
                        <Route path="archive" element={<ArchivedContent handle={{ crumb: () => 'archive' }} />} />
                    </Route>
                    <Route path="microsoft-form" element={<EacParticipationResponses />} handle={{ crumb: () => 'microsoft-form' }} />
                    <Route path="eac-participation-response" element={<AddEACParticipationResponse />} handle={{ crumb: () => 'eac-participation-response' }} />
                    <Route path="cohort-management" handle={{ crumb: () => 'cohort-management' }}>
                        <Route index element={<CohortManagement />} />
                        <Route path="edit/:cohortId" element={<EditCohort />} handle={{ crumb: () => 'edit' }} />
                        <Route path="merge" element={<MergeCohort />} handle={{ crumb: () => 'merge' }} />
                        <Route path="archive" element={<ArchivedCohorts />} handle={{ crumb: () => 'archive' }} />
                        <Route path="members/:cohortId/:cohortStatus" element={<MemberList />} handle={{ title: 'Cohort Members', crumb: () => 'members' }} />
                        <Route path="cohort-upload-overview/:cohortId" element={<CohortUploadOverview />} handle={{ crumb: () => 'cohort-upload-overview' }} />
                    </Route>
                    <Route path="system-notifications" handle={{ crumb: () => 'system-notifications' }} >
                        <Route index element={<SystemNotification />} />
                    </Route>
                </Route>

                {/* Quality Management Views */}
                <Route path="quality-management" handle={{ subscription: 'qualityManagement', crumb: () => 'quality-management' }}>
                    <Route index element={<QualityManagementHome />} />
                    <Route path="hospital" handle={{ crumb: () => 'hospital' }}>
                        <Route index element={<QualityManagementHospitalHome />} />
                        <Route path="star-explorer" handle={{ crumb: () => 'star-explorer' }}>
                            <Route index element={<StarExplorerHome />} />
                            <Route path="national-dashboard" element={<StarExplorerNationalDashboard />} handle={{ crumb: () => 'national-dashboard' }} />
                            <Route path="state-overview" handle={{ crumb: () => 'state-overview' }} >
                                <Route index element={<StateOverview />} />
                                <Route path="state-detail" element={<StateDetail />} handle={{ crumb: () => 'state-detail' }} />
                            </Route>
                        </Route>
                        <Route path="star-report" handle={{ crumb: () => 'star-report' }} >
                            <Route index element={<ReportCard />} />
                            <Route path="mortality" element={<Mortality />} handle={{ crumb: () => 'mortality' }} />
                            <Route path="readmission" element={<Readmission />} handle={{ crumb: () => 'readmission' }} />
                            <Route path="safety" element={<Safety />} handle={{ crumb: () => 'safety' }} />
                            <Route path="patient-exp" element={<PatientExp />} handle={{ crumb: () => 'patient-exp' }} />
                            <Route path="timely" element={<Timely />} handle={{ crumb: () => 'timely' }} />
                            <Route path="multi-facility" element={<MultiFacility />} handle={{ crumb: () => 'multi-facility' }} />
                        </Route>
                        <Route path="star-planner" element={<StarPlanner />} handle={{ crumb: () => 'star-planner' }} />
                    </Route>
                </Route>

                {/* Retention Management Views */}
                <Route path="member-analytics" handle={{ subscription: 'memberAnalytics', crumb: () => 'member-analytics' }} >
                    <Route index element={<MemberManagementHome />} />
                    <Route path="population-characterization" element={<PopulationCharacterization />} handle={{ crumb: () => 'population-characterization' }} />
                    <Route path="retention-risk" handle={{ crumb: () => 'retention-risk' }} >
                        <Route index element={<RetentionRisk />} />
                        <Route path="risk-identification" element={<RiskIdentification />} handle={{ crumb: () => 'risk-identification' }} />
                        <Route path="risk-report" element={<RiskScore />} handle={{ crumb: () => 'risk-report' }} />
                        <Route path="risk-report/:cohortId" element={<RiskScore />} handle={{ crumb: () => 'risk-report' }} />
                        <Route path="archive/cohorts" element={<ArchivedRetentionRiskCohorts />} handle={{ crumb: () => 'archive' }} />
                        <Route path="cohort-upload-overview/:cohortId" element={<CohortUploadOverview />} handle={{ crumb: () => 'cohort-upload-overview' }} />
                        <Route path="risk-monitor" handle={{ crumb: () => 'risk-monitor' }} element={<RiskMonitoringGraphic />} />
                        <Route path="risk-explorer" handle={{ crumb: () => 'risk-explorer' }} >
                            <Route index element={<RiskExplorer />} />
                            <Route path="company-view" element={<CompanyView />} handle={{ title: 'Risk Explorer - Company View', crumb: () => 'company-view' }} />
                            <Route path="cohort-view" element={<CohortView />} handle={{ title: 'Risk Explorer - Cohort View', crumb: () => 'cohort-view' }} >
                                <Route index element={<RiskExploration />} />
                                <Route path="core-demographics" element={<RiskCoreDemographics />} handle={{ crumb: () => 'core-demographics' }} />
                                <Route path="geographic-dispersion" element={<RiskGeographicDispersion />} handle={{ crumb: () => 'geographic-dispersion' }} />
                                <Route path="technology" element={<RiskTechnologyLiteracy />} handle={{ crumb: () => 'technology' }} />
                                <Route path="health-access" element={<RiskHealthAccess />} handle={{ crumb: () => 'health-access' }} />
                                <Route path="financial-index" element={<RiskFinancialIndex />} handle={{ crumb: () => 'financial-index' }} />
                                <Route path="other-insights" element={<RiskOtherInsights />} handle={{ crumb: () => 'other-insights' }} />
                            </Route>
                        </Route>
                        <Route path="risk-manager" element={<RiskManager />} handle={{ crumb: () => 'risk-manager' }} />
                    </Route>
                </Route>

                {/* Retention Management Views */}
                <Route path="advisory-group-management" handle={{ subscription: 'advisoryGroupManagement', crumb: () => 'advisory-group-management' }} >
                    <Route index element={<AdvisoryGroupHome />} />
                    <Route path="analytics" handle={{ crumb: () => 'analytics' }} >
                        <Route index element={<AdvisoryGroupAnalytics />} />
                        <Route path="population-characterization" element={<AnalyticsPopulationCharacterization />} handle={{ crumb: () => 'population-characterization' }} />
                        <Route path="representativeness" element={<Representativeness />} handle={{ crumb: () => 'representativeness' }} />
                        <Route path="health-equity" element={<HealthEquity />} handle={{ crumb: () => 'health-equity' }} />
                        <Route path="cahps-assessment" element={<Assessment />} handle={{ crumb: () => 'cahps-assessment' }} />
                    </Route>
                    <Route path="document-management" element={<AdvisoryGroupDocumentManagement />} handle={{ crumb: () => 'document-management' }} />
                    <Route path="operations" handle={{ title: 'Advisory Group Operations', crumb: () => 'operations' }}>
                        <Route index element={<AdvisoryGroupOperations />} />
                        <Route path="application-detail" element={<ApplicationDetail />} handle={{ crumb: () => 'application-detail', back: true, }} />
                        <Route path="view/:agId" element={<OperationDetails />} handle={{ title: 'Advisory Group Operations Details', back: true, }} />
                        <Route path="master-meeting-schedule" element={<MasterSchedule />} handle={{ title: 'Advisory Group Operations', crumb: () => 'master-meeting-schedule' }} />
                        <Route path="support-staff" element={<SupportStaff />} handle={{ title: 'Support Staff', crumb: () => 'support-staff' }} />
                        <Route path="meeting-details/:meetingId/:agId" element={<MeetingDetails />} handle={{ title: 'Advisory Group Meeting Details', back: true, crumb: () => 'meeting-details' }} />
                    </Route>
                    <Route path="program-management" handle={{ crumb: () => 'program-management' }}>
                        <Route index element={<AdvisoryGroupProgramManagement />} />
                        <Route path="archive" element={<ArchivedPrograms />} handle={{ crumb: () => 'archive' }} />
                    </Route>
                    <Route path="recruitment" handle={{ crumb: () => 'recruitment' }}>
                        <Route index element={<AdvisoryGroupRecruitment />} />
                        <Route path="status" element={<RecruitmentStatus />} handle={{ crumb: () => 'status' }} />
                        <Route path="add" element={<RecruitmentStatus />} handle={{ crumb: () => 'add' }} />
                        <Route path="analyze" element={<RecruitmentStatus />} handle={{ crumb: () => 'analyze' }} />
                        <Route path="application-detail" element={<ApplicationDetail />} handle={{ crumb: () => 'application-detail' }} />
                        <Route path="panel-selection" element={<PanelSelection />} handle={{ crumb: () => 'panel-selection' }} />
                        <Route path="recruiting-outreach" element={<RecruitingOutreach />} />
                        <Route path="panel-maintenance" element={<PanelMaintenance />} handle={{ crumb: () => 'panel-maintenance' }} />
                        <Route path="recruiting-analytics" handle={{ crumb: () => 'recruiting-analytics' }} >
                            <Route index element={<RecruitingAnalytics />} />
                            <Route path="ag-view" element={<RecruitingAgView />} handle={{ crumb: () => 'ag-view' }} />
                            <Route path="company-view" element={<RecruitingCompanyView />} handle={{ crumb: () => 'company-view' }} />
                        </Route>
                    </Route>
                    <Route path="regulatory-reporting" element={<AdvisoryGroupRegulatoryReporting />} handle={{ crumb: () => 'regulatory-reporting' }} />
                </Route>

                {/* Market Assessment Views */}
                <Route path="market-assessment" handle={{ subscription: 'marketAssessment', crumb: () => 'market-assessment' }} >
                    <Route index element={<MarketAssessmentHome />} />
                </Route>

                {/* Plan Design and Fit Views */}
                <Route path="plan-design" handle={{ subscription: 'planDesign', crumb: () => 'plan-design' }} >
                    <Route index element={<PlanDesignFitHome />} />
                </Route>

                {/* User Notifications */}
                <Route path="system-notifications" element={<UserNotifications />} handle={{ crumb: () => 'system-notifications', roles: [USER_ROLE.USER] }} />

                {/* Temporary Views */}
                <Route path="react-table" element={<CompanyReactTable />} handle={{ crumb: () => 'react-table' }} />
                <Route path="nested-table" element={<NestedReactTable />} handle={{ crumb: () => 'nested-table' }} />
                <Route path="grouped-table" element={<GroupedReactTable />} handle={{ crumb: () => 'grouped-table' }} />

                {/* Common Views */}
                <Route path="/logout-redirect" element={<PasswordChangedRedirect />} />
                <Route path="/error-redirect" element={<ErrorRedirect />} />
                <Route path="/session-expired" element={<SessionExpired />} />
                <Route path="/session-logout" element={<SessionLogout />} />
                <Route path="/unauthorized" element={<NotFound showUnauthorized={true} />} />
                <Route path="*" element={<NotFound />} />

            </Route>
        )
    )

    return (
        <RouterProvider router={router} fallbackElement={<div className="flex-grow-1 position-relative"><Loader /></div>} />
    )
}

export default Pages;
