import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/ui/Loader';
import DataGrid from '../../../../components/DataGrid';
import ActionBar from '../../../../components/ui/ActionBar';
import GridButton from '../../../../components/ui/GridButton';
import Icon from '../../../../components/ui/Icon';
import EditMeetingSupportStaffModal from './EditMeetingSupportStaffModal';
import SupportStaffCommentsModal from './SupportStaffCommentsModal';
import ConfirmModal from '../../../../components/ui/ConfirmModal';
import { getSupportStaffAssignedList } from '../../../../ducks/advisoryGroup';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import { AlertContext } from '../../../../context/AlertContext';
import messages from '../../../../utils/helper/messages';
import {
    AG_MEETING_STATUS_INVERSE,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE, ALERT_TYPE,
} from '../../../../constants';
import api from '../../../../services/api/advisoryGroupService';

const MeetingSupportStaff = ({ meeting, locations }) => {
    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector(state => state.user.currentUser);
    const supportStaff = useSelector((state) => state.advisoryGroup.supportStaffAssignedList);

    const [supportStaffData, setSupportStaffData] = useState(null);

    // Edit Support Staff
    const [editSupportStaffModal, setEditSupportStaffModal] = useState(false);

    // Support Staff Comments
    const [supportStaffCommentsModal, setSupportStaffCommentsModal] = useState(false);

    // Cancel Support Staff
    const [cancelSupportStaffModal, setCancelSupportStaffModal] = useState(false);

    const supportStaffColumns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            sortingFn: 'text',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            sortingFn: 'text',
        },
        {
            id: 'organization',
            header: 'Organization',
            accessorKey: 'organization',
            sortingFn: 'text',
            filterFn: 'equalsString',
        },
        {
            id: 'role',
            header: 'Role',
            accessorKey: 'role',
        },
        {
            id: 'name',
            header: 'Location',
            accessorKey: 'name',
        },
        {
            id: 'cellPhone',
            header: 'Cell Phone',
            accessorKey: 'cellPhone',
        },
        {
            id: 'status',
            header: 'Pre-Meeting Response',
            accessorKey: 'status',
            cell: ({ row }) => AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE[row.original.status],
            sortingFn: 'text',
            accessorFn: (row) => AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE[row.status],
        },
        {
            id: 'statusPost',
            header: 'Meeting Attendance',
            accessorKey: 'statusPost',
            cell: ({ row }) => AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE[row.original.statusPost],
            sortingFn: 'text',
            accessorFn: (row) => AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE[row.statusPost],
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 120,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                        disabled={[AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented].includes(meeting.status)}
                        onClick={() => handleEditSupportStaffModalOpen(row.original)}>
                        <Icon icon="edit" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Comments" disabled={[AG_MEETING_STATUS_INVERSE.Documented, AG_MEETING_STATUS_INVERSE.Cancelled].includes(meeting.status)}
                        onClick={() => handleSupportStaffCommentsModalOpen(row.original)}>
                        <Icon icon="comments" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Cancel"
                        disabled={
                            [AG_MEETING_STATUS_INVERSE.Completed, AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented].includes(meeting.status) ||
                            row.original.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled ||
                            meeting.isTodayOrPast}
                        onClick={() => handleCancelSupportStaff(row.original)}>
                        <Icon icon="canceled" size={14} />
                    </GridButton>
                </>
            )
        }
    ];

    const handleEditSupportStaffModalClose = () => {
        setEditSupportStaffModal(false);
        setSupportStaffData(null);
    }

    const handleEditSupportStaffModalOpen = (data) => {
        setSupportStaffData(data);
        setEditSupportStaffModal(true);
    }

    const handleSupportStaffCommentsModalOpen = (data) => {
        setSupportStaffData(data);
        setSupportStaffCommentsModal(true);
    }

    const handleSupportStaffCommentsModalClose = () => {
        setSupportStaffCommentsModal(false);
        setSupportStaffData(null);
    }

    const handleCancelSupportStaff = (data) => {
        setSupportStaffData(data);
        setCancelSupportStaffModal(true);
    }

    const handleConfirm = () => {
        const submitData = {
            id: supportStaffData.attendanceLogId,
            locationId: supportStaffData.locationId ?? null,
            comment: supportStaffData.comment ?? null,
            status: AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled,
            statusPost: AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.Cancelled
        }

        api.UpdateSupportStaffAttendanceLog(submitData)
            .then(() => {
                showAlert(messages.success.cancelSupportStaffAssigned, ALERT_TYPE.SUCCESS);
                dispatch(getSupportStaffAssignedList(meeting.id));
                handleConfirmClose();
            })
            .catch(error => {
                showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            })
    }

    const handleConfirmClose = () => {
        setCancelSupportStaffModal(false);
        setSupportStaffData(null);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getSupportStaffAssignedList(meeting.id));
        }
    }, [dispatch, isAppLoaded, currentUser, meeting.id]);

    return (
        <div>
            <ActionBar title="Support Staff" />
            {supportStaff === null ? (
                <Loader />
            ) : (
                <DataGrid
                    data={supportStaff}
                    columns={supportStaffColumns}
                    enableFilters={false}
                />
            )}

            {editSupportStaffModal && (
                <EditMeetingSupportStaffModal
                    show={editSupportStaffModal}
                    data={supportStaffData}
                    locations={locations}
                    meeting={meeting}
                    onCancel={handleEditSupportStaffModalClose}
                />
            )}

            {supportStaffCommentsModal && (
                <SupportStaffCommentsModal
                    show={supportStaffCommentsModal}
                    data={supportStaffData}
                    meeting={meeting}
                    onCancel={handleSupportStaffCommentsModalClose}
                />
            )}

            {cancelSupportStaffModal && (
                <ConfirmModal
                    show={cancelSupportStaffModal}
                    message={messages.confirm.cancelSupportStaff}
                    onConfirm={handleConfirm}
                    onCancel={handleConfirmClose}
                    onHideCallback={handleConfirmClose}
                />
            )}
        </div>
    )
}

export default MeetingSupportStaff;
