import { apiInstance } from '../apiInstance';
import { protectedResources } from '../../msalAuthConfig';

const headers = {
    'Content-Type': 'application/json',
};

const contentApi = {
    GetContentData: async () => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}`,
            headers,
        });
    },

    GetContentDataById: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}/${id}`,
            headers,
        });
    },

    GetContentDataByStatus: async (status) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}/status/${status}`,
            headers,
        });
    },

    GetContentDataByApplication: async (application) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}/application/${application}`,
            headers,
        });
    },

    GetContentRecordByType: async (type) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}/type/${type}`,
            headers,
        });
    },

    GetProductUpdatesData: async () => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}/product/updates`,
            headers,
        });
    },

    CreateContentData: async (data, onUploadProgress) => {
        const formData = new FormData();
        formData.append('type', data.type);
        formData.append('title', data.title);
        formData.append('audienceSegment', data.audienceSegment);
        formData.append('audienceApplication', data.audienceApplication);
        formData.append('audienceUser', data.audienceUser);
        formData.append('format', data.format);
        formData.append('fileName', data.fileName);
        formData.append('file', data.file);
        formData.append('recordStatus', data.recordStatus);
        formData.append('createUserId', data.createUserId);
        formData.append('updateUserId', data.updateUserId);
        formData.append('graphicFile', data.graphicFileObj);
        formData.append('graphicFileName', data.graphicFile);
        formData.append('summary', data.summary);

        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiContent.endpoint}/`,
            headers: {
                'Content-Type': 'multipart/form-data; boundary=AaB03x' +
                    '--AaB03x' +
                    'Content-Disposition: file' +
                    'Content-Type: png' +
                    'Content-Transfer-Encoding: binary' +
                    '...data... ' +
                    '--AaB03x--',
                Accept: 'application/json',
                type: 'formData',
            },
            data: formData,
            onUploadProgress
        });
    },

    UpdateContentData: async (data, onUploadProgress) => {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append('type', data.type);
        formData.append('title', data.title);
        formData.append('audienceSegment', data.audienceSegment);
        formData.append('audienceApplication', data.audienceApplication);
        formData.append('audienceUser', data.audienceUser);
        formData.append('format', data.format);
        formData.append('fileName', data.fileName);
        formData.append('file', data.file);
        formData.append('recordStatus', data.recordStatus);
        formData.append('createUserId', data.createUserId);
        formData.append('updateUserId', data.updateUserId);
        formData.append('graphicFile', data.graphicFileObj);
        formData.append('graphicFileName', data.graphicFile);
        formData.append('summary', data.summary);


        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiContent.endpoint}/${data.id}`,
            headers: {
                "Content-Type": "multipart/form-data; boundary=AaB03x" +
                    "--AaB03x" +
                    "Content-Disposition: file" +
                    "Content-Type: png" +
                    "Content-Transfer-Encoding: binary" +
                    "...data... " +
                    "--AaB03x--",
                "Accept": "application/json",
                "type": "formData"
            },
            data: formData,
            onUploadProgress
        })
    },

    GetContentFile: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiContent.endpoint}/${id}/file`,
            headers
        })
    },

    ArchiveMultiContent: async (data, status) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiContent.endpoint}/statuses/${status}`,
            headers,
            data
        });
    },

    ArchiveContent: (data) => {
        const { id, status } = data
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiContent.endpoint}/${id}/status/${status}`,
            headers,
            data
        })
    }
};

export default contentApi;
