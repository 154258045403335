import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ActionBar from "../../../components/ui/ActionBar";
import DataGrid from "../../../components/DataGrid";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import withAuth from "../../../components/hoc/withAuth";
import Loader from "../../../components/ui/Loader";
import { isBeforeToday, toDate } from "../../../utils/utils";
import OverflowText from "../../../components/ui/OverflowTooltip";
import { AG_MEETING_STATUS, PROGRAM_MEETING_TYPE, AG_MEETING_STATUS_INVERSE } from "../../../constants";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import { Button } from "react-bootstrap";
import { getAdvisoryGroupMasterScheduleData } from "../../../ducks/advisoryGroup";
import CalendarView from "./CalendarView";
import NewMeetingModal from "./operation-details/NewMeetingModal";

const MasterSchedule = () => {
    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useSelector((state) => state.user.currentUser);
    const allMeetings = useSelector((state) => state.advisoryGroup.scheduleList);
    const [calendarView, setCalendarView] = useState(false);
    const [historicalView, setHistoricalView] = useState(false);
    const [meetings, setMeetings] = useState([]);
    const [pastMeetings, setPastMeetings] = useState([]);
    const [showMeetingModal, setShowMeetingModal] = useState(false);

    const defaultSort = [
        {
            id: 'nextMeetingTime',
            desc: false,
        },
    ];

    const historicalSort = [
        {
            id: 'nextMeetingTime',
            desc: true,
        },
    ];

    const meetingParticipation = historicalView === true ?
        [{
            id: 'meetingParticipation',
            header: 'Meeting Participation',
            columns: [
                {
                    id: 'attendedCount',
                    header: 'Attended',
                    accessorKey: 'attendedCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                    cell: ({ row }) => row.original.attendedCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'canceledCount',
                    header: 'Cancelled',
                    accessorKey: 'canceledCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                    cell: ({ row }) => row.original.canceledCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'noShowCount',
                    header: 'No Show',
                    accessorKey: 'noShowCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                    cell: ({ row }) => row.original.noShowCount ?? 0,
                    aggregatedCell: () => null,
                },
                {
                    id: 'unplannedCount',
                    header: 'Unplanned',
                    accessorKey: 'unplannedCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                    cell: ({ row }) => row.original.unplannedCount ?? 0,
                    aggregatedCell: () => null,
                },
            ],
        }
        ] : [];

    const columns = [
        {
            id: 'nextMeetingTime',
            header: historicalView ? 'Past Mtg Date' : 'Next Mtg Date',
            accessorKey: 'nextMeetingTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.nextMeetingTime)),
            cell: ({ row }) => `${toDate(row.original.nextMeetingTime)}`,
            sortingFn: 'datetime',
            enableColumnFilter: false,
            size: 150,
        },
        {
            id: 'shortName',
            header: 'AG Name',
            accessorKey: 'shortName',
            sortingFn: 'text'
        },
        {
            id: 'meetingLocation',
            header: 'Location',
            sortType: 'text',
            accessorKey: 'meetingLocation'
        },
        {
            id: 'meetingLocationContact',
            header: 'Location Contact',
            sortType: 'text',
            accessorKey: 'meetingLocationContact'
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            accessorFn: (row) => (AG_MEETING_STATUS[row.status]),
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id} label={AG_MEETING_STATUS[row.original.status]}>
                    <div className={`px-2${['P', 'S'].includes(row.original.status) && isBeforeToday(row.original.nextMeetingTime) ? ' bg-red' : ''}`}>
                        {AG_MEETING_STATUS[row.original.status]}
                    </div>
                </OverflowText>
            ),
        },
        {
            id: 'type',
            header: 'Type',
            accessorKey: 'type',
            accessorFn: (row) => (PROGRAM_MEETING_TYPE[row.type]),
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_MEETING_TYPE[row.original.type]}
                </OverflowText>
            ),
        },
        {
            id: 'preMeetingResponse',
            header: 'Pre-meeting Response',
            columns: [
                {
                    id: 'confirmedCount',
                    header: 'Confirmed',
                    accessorKey: 'confirmedCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                },
                {
                    id: 'noResponseCount',
                    header: 'No Response',
                    accessorKey: 'noResponseCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                },
                {
                    id: 'declinedCount',
                    header: 'Declined',
                    accessorKey: 'declinedCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                },
                {
                    id: 'maybeCount',
                    header: 'Maybe',
                    accessorKey: 'maybeCount',
                    enableSorting: false,
                    enableColumnFilter: false,
                },
            ]
        },
        ...meetingParticipation,
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            enableColumnFilter: false,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title={"View"}
                        onClick={() => navigate(`/advisory-group-management/operations/meeting-details/${row.original.id}/${row.original.aGId}`)}>
                        <Icon icon={"view"} size={14} />
                    </GridButton>
                </>
            )
        },
    ];

    const renderContent = () => {
        if (calendarView) {
            return <CalendarView data={meetings} />
        } else {
            return (<>
                <div className={!historicalView && 'd-none'}>
                    <DataGrid
                        data={pastMeetings}
                        columns={columns}
                        sort={historicalSort}
                    />
                </div>
                <div className={historicalView && 'd-none'}>
                    <DataGrid
                        data={meetings}
                        columns={columns}
                        sort={defaultSort}
                    />
                </div>
            </>)
        }
    }

    const handleConfirmClose = () => {
        setShowMeetingModal(false);
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupMasterScheduleData(currentUser?.companyId));
        }
    }, [isAppLoaded]);

    useEffect(() => {
        if (allMeetings) {
            setMeetings(allMeetings.filter(item => !isBeforeToday(item.nextMeetingTime) && [AG_MEETING_STATUS_INVERSE.Planned, AG_MEETING_STATUS_INVERSE.Scheduled].includes(item.status)));
            setPastMeetings(allMeetings.filter(item => isBeforeToday(item.nextMeetingTime) && item.locationId !== null));
        }
    }, [allMeetings]);

    return (
        <div className="flex-grow-1">
            <ActionBar
                title={`Master Meeting Schedule${historicalView ? ': Historical View' : ''}`}
                back={true}>
                <div>
                    <Button
                        className="me-2"
                        type="button"
                        variant="primary"
                        onClick={() => setShowMeetingModal(true)}>
                        New Meeting
                    </Button>
                    {!calendarView && (
                        <Button
                            type="button"
                            variant="primary"
                            onClick={() =>
                                setHistoricalView(!historicalView)
                            }>
                            {historicalView ? 'Current View' : 'Historical View'}
                        </Button>
                    )}
                    {
                        !historicalView &&
                        <Button
                            type="button"
                            variant="primary"
                            className="ms-2"
                            onClick={() => setCalendarView(!calendarView)}>
                            {calendarView ? 'Detail View' : 'Calendar View'}
                        </Button>
                    }
                </div>
            </ActionBar>
            {allMeetings === null ? (
                <Loader />
            ) : renderContent()}
            <NewMeetingModal
                show={showMeetingModal}
                onCancel={handleConfirmClose}
                agSelector={true}
            />
        </div>
    );
}

export default withAuth(MasterSchedule);
