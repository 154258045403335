import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../services/api/contentService';
import Loader from './Loader';
import Style from './ContentFileModal.module.scss';
import PdfViewer from './PdfViewer';
import { ALERT_TYPE, MIME_TYPES } from '../../constants';
import { AlertContext } from '../../context/AlertContext';
import messages from '../../utils/helper/messages';

function ContentFileModal({ contentId, title, contentType, show, modalClose, className }) {
    const { showAlert } = useContext(AlertContext);

    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (contentId) {
            setIsLoading(true);
            api.GetContentFile(contentId)
                .then((response) => {
                    const { data, error } = response;
                    if (error) {
                        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
                        handleClose();
                    } else {
                        if (!data.contentFile) {
                            showAlert(messages.error.fileNotFound, ALERT_TYPE.ERROR);
                        }
                        setFile(data.contentFile);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
                    setIsLoading(false);
                    handleClose();
                });
        }
    }, [contentId]);

    const handleClose = () => {
        setFile(null);
        modalClose();
    };

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            dialogClassName={`${className}`}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${Style.modalBody}`}>
                <div className="d-flex align-items-center justify-content-center">
                    {!isLoading ? (
                        contentType === MIME_TYPES.PDF ? <PdfViewer file={file} /> :
                            <object
                                data={file}
                                type={contentType}
                                className="w-100"
                            />
                    ) : (
                        <Loader
                            className="d-flex align-items-center"
                            animation="border"
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ContentFileModal;
