import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { setLocation } from "../../ducks/navigation";
import styles from "./HeaderBar.module.scss";
import { FaHome, FaUserLock } from "react-icons/fa";
import pageString from "../../utils/helper/pageString";
import { isUserAdmin } from "../../utils/utils";
import Icon from "../ui/Icon";
import { APP_ICONS, SUBSCRIPTION } from '../../constants';
import { SubscriptionContext } from '../../context/SubscriptionContext';


function HeaderBar() {
    const pageLocation = useSelector((state) => state.location.currentLocation);
    const appSubscription = useContext(SubscriptionContext);

    const [title, setTitle] = useState(pageLocation.page);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const topNav = pageString.navigation;

    const handleSelect = (eventKey, event) => {
        let el = event.target;
        let name = el.dataset.name;
        let path = el.dataset.path;

        dispatch(setLocation({ path: path, name: name, page: name }));
        navigate(path);
    }

    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        setTitle(pageLocation.page);
    }, [pageLocation.page]);

    return (
        <div className={`px-3 flex-shrink-0 ${styles.pageTitle}`}>
            <Row className="align-items-center">
                <Col md={3} xxl={2}>
                    <div className={`${styles.topNav} ps-3 pe-1`}>
                        {appSubscription && (
                            <DropdownButton
                                title={<span className="text-truncate" title={pageLocation.name}>{pageLocation.name}</span>}
                                id="main-navigation"
                                onSelect={handleSelect}
                                variant="light"
                                menuRole="menu"
                                className={`${styles.navDropdown} app-dropdown`}
                            >
                                <Dropdown.Item data-name={topNav.home} data-path="/" eventKey="home" className={styles.link}><FaHome className={`${styles.iconSize18} me-2`} />{topNav.home}</Dropdown.Item>
                                {
                                    isUserAdmin(currentUser) ?
                                        <Dropdown.Item data-name={topNav.adminCenter} data-path="/admin" eventKey="adminCenter" className={styles.link}><FaUserLock className={`${styles.iconSize18} me-2`} />{topNav.adminCenter}</Dropdown.Item>
                                        : null
                                }
                                {appSubscription.includes(SUBSCRIPTION.advisoryGroupManagement) && (
                                    <Dropdown.Item
                                        data-name={topNav.advisoryGroupManagement}
                                        data-path="/advisory-group-management"
                                        eventKey="advisoryGroupManagement"
                                        className={styles.link}>
                                        <Icon icon={APP_ICONS.advisoryGroupManagement} size={18} className="me-2" />
                                        {topNav.advisoryGroupManagement}
                                    </Dropdown.Item>
                                )}
                                {appSubscription.includes(SUBSCRIPTION.marketAssessment) && (
                                    <Dropdown.Item
                                        data-name={topNav.marketAssessment}
                                        data-path="/market-assessment"
                                        eventKey="marketAssessment"
                                        className={styles.link}>
                                        <Icon icon={APP_ICONS.marketAssessment} size={18} className="me-2" />
                                        {topNav.marketAssessment}
                                    </Dropdown.Item>
                                )}
                                {appSubscription.includes(SUBSCRIPTION.memberAnalytics) && (
                                    <Dropdown.Item
                                        data-name={topNav.memberAnalytics}
                                        data-path="/member-analytics"
                                        eventKey="memberAnalytics"
                                        className={styles.link}>
                                        <Icon icon={APP_ICONS.memberAnalytics} size={18} className="me-2" />
                                        {topNav.memberAnalytics}
                                    </Dropdown.Item>
                                )}
                                {appSubscription.includes(SUBSCRIPTION.planDesign) && (
                                    <Dropdown.Item
                                        data-name={topNav.planDesign}
                                        data-path="/plan-design"
                                        eventKey="planDesign"
                                        className={styles.link}>
                                        <Icon icon={APP_ICONS.planDesign} size={18} className="me-2" />
                                        {topNav.planDesign}
                                    </Dropdown.Item>
                                )}
                                {appSubscription.includes(SUBSCRIPTION.qualityManagement) && (
                                    <Dropdown.Item
                                        data-name={topNav.qualityManagement}
                                        data-path="/quality-management"
                                        eventKey="qualityManagement"
                                        className={styles.link}>
                                        <Icon icon={APP_ICONS.qualityManagement} size={18} className="me-2" />
                                        {topNav.qualityManagement}
                                    </Dropdown.Item>
                                )}
                            </DropdownButton>
                        )}
                    </div>
                </Col>
                <Col md={6} xxl={8} className="text-center">
                    <h1 className={`${styles.title} mb-0`}>{title}</h1>
                </Col>
                <Col md={3} xxl={2} />
            </Row>
        </div>
    );
}

export default HeaderBar;
