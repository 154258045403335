import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { AlertContext } from '../../../../context/AlertContext';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../../components/ui/Form';
import {
    AG_MEETING_ATTENDANCE_LINK_TYPE,
    AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST,
    AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE,
    AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE_VALUE,
    ALERT_TYPE,
    ALIGN,
    POSITIONS,
    VALIDATIONS,
} from '../../../../constants';
import { isObjectEmpty, omitProps, optionValues, pickProps } from '../../../../utils/utils';
import {
    addNonMemberAttendance,
    getNonMemberAttendanceByMeetingId,
    updateNonMemberAttendance,
} from '../../../../ducks/nonMembers';
import messages from '../../../../utils/helper/messages';
import { addComment, getCommentsByMeetingId, resetMeetingMemberComments } from '../../../../ducks/advisoryGroup';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import Comment from '../../../../components/ui/Comment';
import Loader from '../../../../components/ui/Loader';

const EditNonMemberAttendanceModal = ({ show, data, info, meeting, locations, onCancel }) => {
    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const comments = useSelector(state => state.advisoryGroup.commentList);

    const [isLoading, setIsLoading] = useState(false);
    const [nonMemberAttendanceDetails, setNonMemberAttendanceDetails] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [commentBox, setCommentBox] = useState(false);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'recordModified',
            labelName: 'Last Update',
            type: data?.recordModified ? 'datetime-local' : 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'preMeetingResponse',
            labelName: 'Pre Meeting Response',
            placeholderName: 'Pre Meeting Response',
            type: 'options',
            optionValues: optionValues(AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE_VALUE, data?.preMeetingResponse === null),
            disabled: meeting.isTodayOrPast,
        },
        {
            key: 'meetingParticipation',
            labelName: 'Meeting Participation',
            placeholderName: 'Meeting Participation',
            type: 'options',
            optionValues: optionValues(!meeting.isTodayOrPast ?
                    (data.meetingParticipation ? AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE :
                        pickProps(AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE, AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST.NA)
                    ) :
                    omitProps(AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE, AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST.NA),
                meeting.isTodayOrPast),
            disabled: !meeting.isTodayOrPast
        },
        {
            key: 'locationId',
            labelName: 'Location',
            placeholderName: 'Location',
            type: 'options',
            optionValues: locationOptions,
            disabled: meeting.isTodayOrPast,
            required: false,
        },
        {
            key: 'comment',
            labelName: commentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !commentBox,
            valid: VALIDATIONS.String.noSpecialChars,
        }
    ];

    const handleCancel = () => {
        onCancel();
        dispatch(resetMeetingMemberComments());
    };

    const handleSuccess = () => {
        setIsLoading(false);
        let successMessage = !info.id ? messages.success.addNonMemberAttendance : messages.success.updateNonMemberAttendance
        showAlert(successMessage, ALERT_TYPE.SUCCESS);
        dispatch(getNonMemberAttendanceByMeetingId(meeting.agId, meeting.id));
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const checkMemberDataEquals = (orgData, subData) => {
        return (orgData.preMeetingResponse === subData.status &&
            orgData.meetingParticipation === subData.statusPost &&
            orgData.locationId === subData.locationId)
    }

    const handleSubmit = (formData) => {
        setIsLoading(true);

        const createAttendanceData = {
            agNonMemberId: info.agNonMemberId,
            companyId: info.companyId,
            agId: meeting.agId,
            meetingId: meeting.id,
            status: meeting.isTodayOrPast ? data.preMeetingResponse : formData.preMeetingResponse,
            statusPost: !meeting.isTodayOrPast ? (data.meetingParticipation ?? AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE.NA) : formData.meetingParticipation,
            locationId: meeting.isTodayOrPast ? (data.locationId || null) : (formData.locationId || null),
        }

        const updateAttendanceData = {
            id: info.id,
            status: meeting.isTodayOrPast ? data.preMeetingResponse : formData.preMeetingResponse,
            statusPost: formData.meetingParticipation ?? AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE.NA,
            locationId: meeting.isTodayOrPast ? (data.locationId || null) : (formData.locationId || null),
        }

        const isMemberDataEqual = checkMemberDataEquals(data, updateAttendanceData);

        if (!info.id) {
            dispatch(addNonMemberAttendance(createAttendanceData, handleSuccess, handleError));
        } else if (!isMemberDataEqual) {
            dispatch(updateNonMemberAttendance(updateAttendanceData, handleSuccess, handleError));
        }

        if (formData.comment) {
            const commentData = {
                agId: meeting.agId,
                meetingId: meeting.id,
                companyId: info.companyId,
                agMemberId: info.agNonMemberId,
                userId: info.userId,
                comment: formData.comment,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.NonMember,
            };

            dispatch(addComment(commentData, () => {
                setIsLoading(false);
                if (isMemberDataEqual) {
                    showAlert(messages.success.addNonMemberComment, ALERT_TYPE.SUCCESS);
                }
                handleCancel();
            }, handleError));
        }
    }

    const isValidNonMemberAttendance = () => {
        return !isObjectEmpty(nonMemberAttendanceDetails);
    }

    useEffect(() => {
        if (locations && locations.length > 0) {
            let locationData = locations.map(location => ({ id: location.id, value: location.name }));
            setLocationOptions([
                { id: '', value: 'Select Location' },
                ...locationData
            ]);
        } else if (locations?.length === 0) {
            setLocationOptions([{ id: '', value: 'No locations available' }])
        }
    }, [locations]);

    useEffect(() => {
        if (data) {
            setNonMemberAttendanceDetails(data);
        }
    }, [data]);

    useEffect(() => {
        if (isAppLoaded && info.agNonMemberId) {
            dispatch(getCommentsByMeetingId({
                meetingId: meeting.id,
                agMemberId: info.agNonMemberId,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.NonMember
            }));
        }
    }, [isAppLoaded, info.agNonMemberId]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Non-Member Attendance Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={nonMemberAttendanceDetails}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        loading={isLoading}
                        disabled={!isValidNonMemberAttendance()}
                    />
                    {comments === null ? (
                        <Loader size={2} />
                    ) : (
                        <Comment
                            comments={comments}
                            onAddComment={() => setCommentBox(true)}
                            commentBox={commentBox}
                            showUserName={true}
                            showDate={true}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EditNonMemberAttendanceModal;
