import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useState, useEffect } from 'react';
import { AG_MEETING_ATTENDANCE_LINK_TYPE, ALERT_TYPE, ALIGN, POSITIONS, VALIDATIONS } from '../../../../constants';
import messages from '../../../../utils/helper/messages';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../../components/ui/Form';
import { addComment, getCommentsByMeetingId, resetMeetingMemberComments } from '../../../../ducks/advisoryGroup';
import { AlertContext } from '../../../../context/AlertContext';
import Comment from '../../../../components/ui/Comment';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import Loader from '../../../../components/ui/Loader';

const SupportStaffCommentsModal = ({ show, meeting, data, onCancel }) => {
    const { showAlert } = useContext(AlertContext);
    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.user?.currentUser);
    const comments = useSelector((state) => state.advisoryGroup?.commentList);

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [commentDetails, setCommentDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAppLoaded && data.id) {
            dispatch(getCommentsByMeetingId({
                meetingId: meeting.id,
                agMemberId: data.id,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.SupportStaff
            }))
        }
    }, [isAppLoaded, data.id]);

    const handleSubmit = (formData) => {
        setIsLoading(true);

        if (formData.comment) {
            const commentData = {
                agId: meeting.agId,
                meetingId: meeting.id,
                companyId: meeting.companyId,
                agMemberId: data.id,
                userId: currentUser.id,
                comment: formData.comment,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.SupportStaff,
            };

            dispatch(addComment(commentData, () => {
                setIsLoading(false);
                showAlert(messages.success.addSupportStaffComment, ALERT_TYPE.SUCCESS);
                setShowCommentBox(false);
                onCancel();
                dispatch(resetMeetingMemberComments());
            }, handleError));
        }
    };

    const handleCancel = () => {
        setShowCommentBox(false);
        onCancel();
        setCommentDetails(null);
        dispatch(resetMeetingMemberComments());
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error?.response?.data?.error || error}.`, ALERT_TYPE.ERROR);
    }

    const handleAddComment = () => setShowCommentBox(true);

    const arr = [
        {
            key: 'comment',
            labelName: showCommentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !showCommentBox,
            maxLength: 255,
            valid: VALIDATIONS.String.noSpecialChars,
        },
    ];

    const handleCommentDetailsChange = (update) => setCommentDetails((details) => ({ ...details, ...update }));

    const inputChange = (name, value) => handleCommentDetailsChange({ [name]: value });

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data?.comment}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={isLoading}
                        disabled={!showCommentBox || commentDetails?.comment === ''}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                        onInputChanged={inputChange}
                    />
                    {comments === null ? (
                        <Loader size={2} />
                    ) : (
                        <Comment
                            comments={comments}
                            onAddComment={handleAddComment}
                            commentBox={showCommentBox}
                            showUserName={true}
                            showDate={true}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SupportStaffCommentsModal;
