import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../../components/ui/Form';
import {
    AG_MEETING_ATTENDANCE_LINK_TYPE,
    AG_MEETING_STATUS_INVERSE, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE,
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE, ALERT_TYPE,
    ALIGN,
    POSITIONS,
    SUPPORT_STAFF_ROLE,
    VALIDATIONS,
} from '../../../../constants';
import { dropdownValues, isObjectEmpty, omitProps, optionValues, pickProps } from '../../../../utils/utils';
import api from '../../../../services/api/advisoryGroupService';
import { AlertContext } from '../../../../context/AlertContext';
import { addComment, getCommentsByMeetingId, getSupportStaffAssignedList, resetMeetingMemberComments } from '../../../../ducks/advisoryGroup';
import messages from '../../../../utils/helper/messages';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import Comment from '../../../../components/ui/Comment';
import Loader from '../../../../components/ui/Loader';

const EditMeetingSupportStaffModal = ({ show, data, locations, meeting, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);
    const isAppLoaded = useContext(AppLoadContext);

    const comments = useSelector(state => state.advisoryGroup.commentList);
    const currentUser = useSelector(state => state.user.currentUser);

    const [locationOptions, setLocationOptions] = useState(null);
    const [supportStaffData, setSupportStaffData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [commentBox, setCommentBox] = useState(false);

    useEffect(() => {
        if (isAppLoaded && data.id) {
            dispatch(getCommentsByMeetingId({
                meetingId: meeting.id,
                agMemberId: data.id,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.SupportStaff
            }))
        }
    }, [isAppLoaded, data.id]);

    const isMeetingPastAndCompleted = (meeting) => {
        return meeting.status !== AG_MEETING_STATUS_INVERSE.Completed && !meeting.isTodayOrPast
    }

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'organization',
            labelName: 'Organization',
            type: 'text',
            disabled: true,
            required: false,
        },
        {
            key: 'role',
            labelName: 'Role',
            type: 'options',
            optionValues: optionValues(SUPPORT_STAFF_ROLE, true, 'Select Role'),
            disabled: true,
            required: false,
        },
        {
            key: 'cellPhone',
            labelName: 'Cellphone',
            type: 'tel',
            disabled: true,
            required: false,
        },
        {
            key: 'status',
            labelName: 'Pre-meeting Status',
            type: 'options',
            optionValues: optionValues(AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_VALUE),
            disabled: meeting.isTodayOrPast,
        },
        {
            key: 'statusPost',
            labelName: 'Post Meeting Status',
            type: 'options',
            optionValues: optionValues(
                isMeetingPastAndCompleted(meeting) ?
                    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE :
                    omitProps(AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST_VALUE, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA),
                (!isMeetingPastAndCompleted(meeting)) && supportStaffData?.statusPost === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA
            ),
            disabled: (!meeting.isTodayOrPast) || data.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled,
        },
        {
            key: 'locationId',
            labelName: 'Location',
            type: 'options',
            optionValues: locationOptions,
            required: true,
            disabled: meeting.isTodayOrPast,
        },
        {
            key: 'comment',
            labelName: commentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !commentBox,
            valid: VALIDATIONS.String.noSpecialChars,
        }
    ];

    const getSupportStaffPostStatus = (formData) => {
        if (data.status !== formData.status) {
            // if status is reverted from cancelled to confirmed or assigned, statusPost is reset to its default status - NA
            if (data.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled) {
                return AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA
            }
            // if status is changed to cancelled, statusPost is automatically changed to Cancelled
            if (formData.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled) {
                return AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.Cancelled
            }
        }
        return formData.statusPost ?? data.statusPost
    }

    const getSupportStaffLocation = (formData) => {
        // if location is disabled
        if (meeting.isTodayOrPast) {
            return data.locationId;
        } else if (formData.locationId === '') {
            return null;
        } else {
            return formData.locationId;
        }
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const checkSupportStaffDataEquals = (preData, postData) => {
        return (preData.status === postData.status && preData.statusPost === postData.statusPost && preData.locationId === postData.locationId)
    };

    const handleSubmit = (formData) => {
        setIsLoading(true);
        const submitData = {
            ...formData,
            id: data.attendanceLogId,
            status: formData.status ?? data.status,
            statusPost: getSupportStaffPostStatus(formData),
            locationId: getSupportStaffLocation(formData),
        }

        const updateSupportStaffData = {
            id: data.id,
            status: meeting.isTodayOrPast ? data.status : formData.status,
            statusPost: (!meeting.isTodayOrPast) || data.status === AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Cancelled ? data.statusPost : formData.statusPost,
            locationId: meeting.isTodayOrPast ? data.locationId : parseInt(formData.locationId),
        };

        const isSupportStaffDataEqual = checkSupportStaffDataEquals(data, updateSupportStaffData);

        if (!isSupportStaffDataEqual) {
            api.UpdateSupportStaffAttendanceLog(submitData)
            .then(() => {
                showAlert(messages.success.updateSupportStaffAssigned, ALERT_TYPE.SUCCESS);
                setIsLoading(false);
                dispatch(getSupportStaffAssignedList(meeting.id));
                onCancel();
            })
            .catch(error => {
                setIsLoading(false);
                showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            })
        }
        
        if (formData.comment) {
            const commentData = {
                agId: meeting.agId,
                meetingId: meeting.id,
                companyId: meeting.companyId,
                agMemberId: data.id,
                userId: currentUser.id,
                comment: formData.comment,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.SupportStaff,
            };
    
            dispatch(addComment(commentData, () => {
                setIsLoading(false);
                if (isSupportStaffDataEqual) {
                    showAlert(messages.success.addSupportStaffComment, ALERT_TYPE.SUCCESS);
                }
                dispatch(getCommentsByMeetingId({
                    meetingId: meeting.id,
                    agMemberId: data.id,
                    linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.SupportStaff
                }));
                onCancel();
            }, handleError));
        }
    }

    const isValidStaffInfo = () => {
        return !isObjectEmpty(supportStaffData);
    }

    const handleSupportStaffChange = (update) => {
        setSupportStaffData((details) => ({ ...details, ...update }))
    }

    const inputChange = (name, value) => {
        handleSupportStaffChange({ [name]: value });
    };

    const handleCancel = () => {
        onCancel();
        dispatch(resetMeetingMemberComments());
    };

    useEffect(() => {
        if (locations && locations.length > 0) {
            const options = dropdownValues(locations, 'name', 'id', true, 'Select Location');
            setLocationOptions(options);
        }
    }, [locations]);

    useEffect(() => {
        if (data) {
            const editData = pickProps(data, [
                'firstName', 'lastName', 'organization', 'role', 'cellPhone', 'status', 'statusPost', 'locationId',
            ]);
            setSupportStaffData({
                ...editData,
                locationId: editData.locationId === null ? '' : String(editData.locationId),
                comment: "",
            });
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Edit Support Staff Attendance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={supportStaffData}
                        loading={isLoading}
                        disabled={!isValidStaffInfo()}
                        onSubmit={handleSubmit}
                        onInputChanged={inputChange}
                        onCancel={handleCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                    {comments === null ? (
                        <Loader size={2} />
                    ) : (
                        <Comment
                            comments={comments}
                            onAddComment={() => setCommentBox(true)}
                            commentBox={commentBox}
                            showUserName={true}
                            showDate={true}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EditMeetingSupportStaffModal;
