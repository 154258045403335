import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
    ALIGN,
    POSITIONS,
    ALERT_TYPE, VALIDATIONS,
    US_STATE,
    PROXY_FLAG
} from "../../../constants";
import SharedForm from '../../../components/ui/Form';
import { checkObjectsEqual, isObjectEmpty, optionValues } from '../../../utils/utils';
import {
    getProxyGuardianMemberById,
    resetProxyMemberData,
    updateMemberProxyData
} from '../../../ducks/advisoryGroup';
import messages from '../../../utils/helper/messages';
import { AlertContext } from '../../../context/AlertContext';
import Loader from '../../../components/ui/Loader';
import ConfirmModal from '../../../components/ui/ConfirmModal';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';

const ProxyAttendeeModal = ({ data, show, onCancel }) => {
    
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);
    const isAppLoaded = useContext(AppLoadContext);

    const [loading, setLoading] = useState(false);
    const [proxyAttendeeData, setProxyAttendeeData] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);

    const currentUser = useSelector(state => state.user?.currentUser);
    const proxyAttendeeDetails = useSelector(state => state.advisoryGroup?.proxyAttendeeDetails);

    const stateOptions = optionValues(US_STATE);

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getProxyGuardianMemberById(data?.id));
        }
    }, [dispatch, isAppLoaded]);

    useEffect(() => {
        if (proxyAttendeeDetails !== null) {
            setProxyAttendeeData(proxyAttendeeDetails);
        }
    }, [proxyAttendeeDetails]);

    const arr = [
        {
            key: 'proxyFirstName',
            labelName: ' Proxy First Name',
            placeholderName: 'Proxy First Name',
            type: 'text',
            valid: VALIDATIONS.String.isName,
            maxLength: 35,
            required: false,
        },
        {
            key: 'proxyLastName',
            labelName: 'Proxy Last Name',
            placeholderName: 'Proxy Last Name',
            type: 'text',
            valid: VALIDATIONS.String.isName,
            maxLength: 35,
            required: false,
        },
        {
            key: 'proxyAddress1',
            labelName: 'Proxy Address',
            placeholderName: 'Proxy Address',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'proxyAddress2',
            labelName: 'Proxy Address Line 2',
            placeholderName: 'Proxy Address Line 2',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'proxyCity',
            labelName: 'Proxy City',
            placeholderName: 'Proxy City',
            type: 'text',
            required: false,
            maxLength: 50,
        },
        {
            key: 'proxyState',
            labelName: 'Proxy State',
            placeholderName: 'Proxy State',
            type: 'options',
            optionValues: stateOptions,
            minLength: 2,
            maxLength: 2,
            required: false,
        },
        {
            key: 'proxyPostalCode',
            labelName: 'Proxy Zip',
            placeholderName: 'Proxy Zip',
            type: 'text',
            minLength: 5,
            maxLength: 9,
            valid: VALIDATIONS.Number.isZipCode,
            required: false,
        },
        {
            key: 'proxyPhone',
            labelName: 'Proxy Phone',
            placeholderName: 'Proxy Phone',
            type: 'tel',
            maxLength: 10,
            required: false,
        },
        {
            key: 'proxyEmail',
            labelName: 'Proxy Email',
            placeholderName: 'Proxy Email',
            type: 'email',
            maxLength: 255,
            required: false
        },
    ];

    const handleCancel = () => {
        if (!checkObjectsEqual(proxyAttendeeDetails, proxyAttendeeData)) {
            setConfirmModal(true);
        } else {
            handleConfirm();
        }
    };

    const handleError = (error) => {
        setLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleProxySuccess = () => {
        handleConfirm();
        showAlert(messages.success.updateProxyDetails, ALERT_TYPE.SUCCESS);
    };

    const submit = (formData) => {

        if (!formData) return;

        const updatedData = {
            memberId: data.id,
            proxyGuardianFlag: PROXY_FLAG.Proxy,
            proxyFirstName: formData.proxyFirstName,
            proxyLastName: formData.proxyLastName,
            proxyAddress1: formData.proxyAddress1,
            proxyAddress2: formData.proxyAddress2,
            proxyCity: formData.proxyCity,
            proxyState: formData.proxyState,
            proxyPostalCode: formData.proxyPostalCode,
            proxyEmail: formData.proxyEmail,
            proxyPhone: formData.proxyPhone,
            lastUsertoUpdateRecord: currentUser.id,
        }

        const isProxyDataEqual = checkObjectsEqual(proxyAttendeeDetails, updatedData);

        if (!isProxyDataEqual) {
            setLoading(true);
            dispatch(updateMemberProxyData(updatedData, handleProxySuccess, handleError));
        }
    };

    const handleProxyDetailsChange = (update) => setProxyAttendeeData((details) => ({ ...details, ...update }));

    const inputChange = (name, value) => handleProxyDetailsChange({ [name]: value });

    const isValidProxyData = () => !isObjectEmpty(proxyAttendeeData);

    const handleConfirm = () => {
        handleConfirmClose();
        onCancel();
        setLoading(false);
        dispatch(resetProxyMemberData());
        setProxyAttendeeData(null);
    };

    const handleConfirmClose = () => setConfirmModal(false);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Proxy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {proxyAttendeeDetails === null ? (
                    <Loader />
                ) : (
                    <div className="px-xl-2 py-xl-2">
                        <SharedForm
                            cols={12}
                            array={arr}
                            modelObj={proxyAttendeeDetails}
                            onSubmit={submit}
                            onCancel={handleCancel}
                            actionBarPosition={POSITIONS.TOP}
                            actionBarAlign={ALIGN.RIGHT}
                            submitButtonText={'Save'}
                            onInputChanged={inputChange}
                            loading={loading}
                            disabled={!isValidProxyData()}
                        />
                    </div>
                )}
                {confirmModal && (
                    <ConfirmModal
                        show={confirmModal}
                        message={messages.confirm.discardProxyEdit}
                        onConfirm={handleConfirm}
                        onCancel={handleConfirmClose}
                        onHideCallback={handleConfirmClose}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ProxyAttendeeModal;
