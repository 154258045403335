import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import ActionBar from "../../../components/ui/ActionBar";
import DataGrid from "../../../components/DataGrid";
import OverflowText from "../../../components/ui/OverflowTooltip";
import ConfirmModal from "../../../components/ui/ConfirmModal";
import withAuth from "../../../components/hoc/withAuth";
import { archiveContent, getContent } from "../../../ducks/content";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import {
    ALERT_TYPE,
    AUDIENCE_APPLICATION,
    AUDIENCE_SEGMENT,
    CMS_CONTENT_TYPE,
    CONTENT_RECORD_STATUS
} from "../../../constants";
import messages from "../../../utils/helper/messages";
import Loader from "../../../components/ui/Loader";
import { toDate } from "../../../utils/utils";
import Icon from "../../../components/ui/Icon";
import GridButton from "../../../components/ui/GridButton";
import AddContentModal from "./AddContentModal";
import NewButton from "../../../components/ui/NewButton";
import { AlertContext } from "../../../context/AlertContext";
import api from '../../../services/api/contentService';

const ContentManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [confirmModal, setConfirmModal] = useState(false);
    const [info, setInfo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);
    const [checkedIds, setCheckedIds] = useState([]);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const contentList = useSelector((state) => state.content.contents);
    const currentUser = useSelector((state) => state.user.currentUser);

    const defaultSort = [
        {
            id: 'updatedDateTime',
            desc: true,
        },
    ];

    const rowActions = ({ row }) => {
        return (
            <div className="d-flex">
                <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                    onClick={() => handleEdit(row.original)}>
                    <Icon icon="edit" size={14} />
                </GridButton>
                <GridButton variant="light" size="sm" className="me-1 my-1" title="Archive"
                    onClick={() => handleArchiveConfirm(row.original)}>
                    <Icon icon="archive" size={14} />
                </GridButton>
            </div>
        )
    }

    const handleEdit = (data) => {
        setShowModal(true);
        setSelectedContent(data);
    };

    const handleArchiveConfirm = (data) => {
        setInfo(data);
        setConfirmModal(true);
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setCheckedIds([]);
        setInfo(null);
    }

    const handleConfirm = () => {
        handleArchive(info);
        handleConfirmClose();
    }

    const handleAddModalClose = () => {
        setSelectedContent(null);
        setShowModal(false);
    }

    const handleSuccess = () => showAlert(messages.success.archiveContent, ALERT_TYPE.SUCCESS);

    const handleError = (error) => showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);

    const handleArchive = (content) => {
        dispatch(archiveContent({
            id: content.id,
            updateUserId: currentUser.id,
            status: CONTENT_RECORD_STATUS.Archived
        }, handleSuccess, handleError));
    };

    const handleMasterCheckboxChange = (event) => {
        if (event.target.checked) {
            const contentIds = contentList.map((content) => content.id);
            setCheckedIds(contentIds);
        } else {
            setCheckedIds([]);
        }
    };

    const rowActionHeader = () => {
        return (
            <label key={0} htmlFor="masterCheck">
                <input
                    id="masterCheck"
                    type="checkbox"
                    checked={contentList?.length === 0 ? false : contentList?.length === checkedIds?.length}
                    disabled={!contentList || contentList?.length === 0}
                    onChange={handleMasterCheckboxChange}
                />
            </label>
        );
    };

    const checkboxRowActions = ({ row }) => {
        return (
            <label key={row.original.id} htmlFor={`input-${row.original.id}`}>
                <input
                    id={`input-${row.original.id}`}
                    type="checkbox"
                    checked={checkedIds.includes(row.original.id)}
                    onChange={() => handleCheckboxChange(row.original.id, event)}
                />
            </label>
        );
    };

    const handleCheckboxChange = (contentId, event) => {
        if (event.target.checked) {
            if (!checkedIds.includes(contentId)) {
                setCheckedIds((prevIds) => [...prevIds, contentId]);
            }
        } else if (checkedIds.includes(contentId)) {
            setCheckedIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== contentId)
            );
        }
    };

    const handleMultiArchive = () => {
        setConfirmModal(true);
    };

    const handleArchiveSuccess = () => {
        showAlert(messages.success.archiveContent, ALERT_TYPE.SUCCESS);
        dispatch(getContent());
        handleConfirmClose();
    };

    const handleArchiveError = (error) => showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);

    const handleMultiArchiveConfirm = () => {
        let contentIds = [];
        if (checkedIds?.length > 0) {
            contentIds = [...checkedIds];
        }

        let contentUpdateData = [];
        for (let contentId of contentIds) {
            contentUpdateData.push({
                contentId: contentId,
                status: CONTENT_RECORD_STATUS.Archived,
                updateUserId: currentUser?.id
            });
        }

        api.ArchiveMultiContent(contentUpdateData, CONTENT_RECORD_STATUS.Archived)
            .then(handleArchiveSuccess)
            .catch(handleArchiveError);
    };

    const columns = [
        {
            id: 'select',
            header: rowActionHeader,
            cell: checkboxRowActions,
            size: 32,
            minSize: 32,
            enableSorting: false,
        },
        {
            id: 'updatedDateTime',
            header: 'Date Updated',
            accessorKey: 'updatedDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.updatedDateTime)),
            cell: ({ row }) => `${toDate(row.original.updatedDateTime)}`,
            size: 150,
            enableColumnFilter: false,
            sortingFn: 'datetime'
        },
        {
            id: 'type',
            header: 'Content Type',
            accessorKey: 'type',
            accessorFn: (row) => `${CMS_CONTENT_TYPE[row.type]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {CMS_CONTENT_TYPE[row.original.type]}
                </OverflowText>
            ),
            minSize: 180,
            size: 180,
        },
        {
            id: 'title',
            header: 'Title',
            accessorKey: 'title',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>{row.original.title}</OverflowText>
            ),
            minSize: 200,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
        {
            id: 'audienceSegment',
            header: 'Audience Segment',
            accessorKey: 'audienceSegment',
            accessorFn: (row) => `${AUDIENCE_SEGMENT[row.audienceSegment]}`,
            cell: ({ row }) => AUDIENCE_SEGMENT[row.original.audienceSegment],
            size: 155,
        },
        {
            id: 'audienceApplication',
            header: 'Audience Application',
            accessorKey: 'audienceApplication',
            accessorFn: (row) => `${AUDIENCE_APPLICATION[row.audienceApplication]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {AUDIENCE_APPLICATION[row.original.audienceApplication]}
                </OverflowText>
            ),
            size: 165,
        },
        {
            id: 'fileName',
            header: 'Content File',
            accessorKey: 'fileName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.fileName}
                </OverflowText>
            ),
            maxSize: 250,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: rowActions,
            size: 90,
        },
    ];

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getContent());
        }
    }, [dispatch, isAppLoaded]);

    return (
        <>
            <ActionBar>
                <Button type="button" className="me-2" variant="outline-primary"
                    onClick={() => navigate(`/admin/content-management/archive`)}>
                    View Archive
                </Button>
                <Button
                    className="me-2"
                    type="button"
                    onClick={handleMultiArchive}
                    disabled={checkedIds.length === 0}
                >
                    Archive
                </Button>
                <NewButton onClick={() => setShowModal(true)} />
            </ActionBar>

            {
                contentList === null ?
                    <Loader />
                    :
                    <DataGrid
                        columns={columns}
                        data={contentList}
                        sort={defaultSort}
                    />
            }

            {showModal && (
                <AddContentModal
                    show={showModal}
                    onCancel={handleAddModalClose}
                    content={selectedContent}
                />
            )}

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.archiveContent}
                onConfirm={checkedIds.length > 0 ? handleMultiArchiveConfirm : handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={checkedIds.length > 0 ? handleConfirm : handleConfirmClose}
            />
        </>
    )
}

export default withAuth(ContentManagement);
