import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from '../../../../components/ui/ActionBar';
import Loader from '../../../../components/ui/Loader';
import DataGrid from '../../../../components/DataGrid';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import { getNonMemberAttendanceByMeetingId } from '../../../../ducks/nonMembers';
import { pickProps, toDateTime } from '../../../../utils/utils';
import {
    AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE, AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE,
    AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE_VALUE,
    AG_MEETING_STATUS_INVERSE,
} from '../../../../constants';
import Icon from '../../../../components/ui/Icon';
import GridButton from '../../../../components/ui/GridButton';
import EditNonMemberAttendanceModal from './EditNonMemberAttendanceModal';
import NonMemberCommentsModal from './NonMemberCommentsModal';

const MeetingNonMembers = ({ meeting, currentUser }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);

    const memberData = useSelector(state => state.nonMembers.meetingAttendance);
    const meetingLocationData = useSelector(state => state.advisoryGroup.meetingLocations);

    const [editModal, setEditModal] = useState(false);
    const [memberDetails, setMemberDetails] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [showCommentModal, setShowCommentModal] = useState(null);

    const columns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            sortingFn: 'text'
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
        },
        {
            id: 'recordModified',
            header: 'Last Update',
            accessorKey: 'Last Update',
            accessorFn: (row) => (new Date(row.recordModified)),
            cell: ({ row }) => `${toDateTime(row.original.recordModified)}`,
            sortType: 'date',
            sortingFn: 'datetime'
        },
        {
            id: 'preMeetingResponse',
            header: 'Pre-Meeting Response',
            accessorKey: 'preMeetingResponse',
            cell: ({ row }) => AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE_VALUE[row.original.preMeetingResponse?.trim()],
            sortingFn: 'text',
        },
        {
            id: 'meetingParticipation',
            header: 'Meeting Participation',
            accessorKey: 'meetingParticipation',
            cell: ({ row }) => row.original.meetingParticipation ? AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE[row.original.meetingParticipation?.trim()] : AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_POST_VALUE.NA,
            sortingFn: 'text',
        },
        {
            id: 'locationName',
            header: 'Location',
            accessorKey: 'locationName',
            sortingFn: 'text',
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 60,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                            disabled={[AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented].includes(meeting?.status)}
                            onClick={() => handleNonMemberModalOpen(row.original)}>
                        <Icon icon="edit" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Comments" disabled={[AG_MEETING_STATUS_INVERSE.Cancelled, AG_MEETING_STATUS_INVERSE.Documented].includes(meeting?.status)}
                        onClick={() => { handleCommentOpen(row.original) }}>
                        <Icon icon={"comments"} size={14} />
                    </GridButton>
                </>
                
            ),
        }
    ];

    const handleCommentOpen = (data) => {
        setShowCommentModal(true);
        setSelectedId(data.id);
        setSelectedMemberId(data.agNonMemberId);
    };

    const handleNonMemberModalOpen = (data) => {
        setEditModal(true);
        const memberData = pickProps(data, ['firstName', 'lastName', 'preMeetingResponse', 'meetingParticipation', 'locationId', 'recordModified']);
        setMemberDetails({
            ...memberData,
            preMeetingResponse: memberData.preMeetingResponse ? memberData.preMeetingResponse : AG_MEETING_NON_MEMBER_ATTENDANCE_STATUS_PRE.NoResponse,
            locationId: memberData.locationId ? memberData.locationId.toString() : null,
        });
        setSelectedMemberId(data.agNonMemberId);
        setSelectedId(data.id);
    }

    const handleNonMemberModalClose = () => {
        setEditModal(false);
        setSelectedId(null);
        setSelectedMemberId(null);
        setMemberDetails(null);
        setShowCommentModal(false);
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getNonMemberAttendanceByMeetingId(meeting.agId, meeting.id))
        }
    }, [isAppLoaded, dispatch]);

    return (
        <>
            <ActionBar title="Non Member Status" />
            {memberData === null ? (
                <Loader />
            ) : (
                <DataGrid
                    data={memberData}
                    columns={columns}
                    enableFilters={false}
                />
            )}

            {editModal && (
                <EditNonMemberAttendanceModal
                    show={editModal}
                    info={{
                        id: selectedId,
                        companyId: currentUser?.companyId,
                        agNonMemberId: selectedMemberId,
                        userId: currentUser?.id,
                    }}
                    meeting={meeting}
                    data={memberDetails}
                    locations={meetingLocationData}
                    onCancel={handleNonMemberModalClose}
                />
            )}
            {showCommentModal && (
                <NonMemberCommentsModal
                show={showCommentModal}
                info={{
                    id: selectedId,
                    companyId: currentUser?.companyId,
                    agNonMemberId: selectedMemberId,
                    userId: currentUser?.id,
                }}
                meeting={meeting}
                data={memberDetails}
                locations={meetingLocationData}
                onCancel={handleNonMemberModalClose}
                onComment={handleNonMemberModalClose}
            />
        )}
        </>
    )
}

export default MeetingNonMembers;
