import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import {
    ALERT_TYPE,
    ALIGN,
    MEETING_STATUS,
    POSITIONS,
    PROGRAM_MEETING_TYPE, TIMEZONE_ABBR, TIMEZONES,
    VALIDATIONS,
    USER_STATUS_VALUE
} from '../../../../constants';
import {
    optionValues,
    todayStartofDay,
    combineDateTimeTimezone,
    dropdownValues,
} from '../../../../utils/utils';
import { AlertContext } from "../../../../context/AlertContext";
import { addMeetingDetails, getAdvisoryGroupData } from '../../../../ducks/advisoryGroup';
import { getCompanyUsersById } from '../../../../ducks/company';
import messages from "../../../../utils/helper/messages";
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';

const NewMeetingModal = ({ show, onCancel, data, agSelector = false }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const [isLoading, setIsLoading] = useState(false);
    const [ownerOptions, setOwnerOptions] = useState(null);
    const [agOptions, setAgOptions] = useState(null);
    const [agShortname, setAgShortname] = useState(null);

    const currentUser = useSelector((state) => state.user.currentUser);
    const companyUsers = useSelector((state) => state.company?.users);
    const advisoryGroupMaster = useSelector((state) => state.advisoryGroup?.masterList);

    useEffect(() => {
        if (companyUsers && companyUsers.length > 0) {
            const users = companyUsers.filter(item => item.recordStatus !== USER_STATUS_VALUE.Archived);
            setOwnerOptions(users.map(user => ({ id: user.id, value: user.name })))
        }
    }, [companyUsers]);

    useEffect(() => {
        if (advisoryGroupMaster?.length > 0) {
            const agData = advisoryGroupMaster.map(({ id, companyId, shortName }) => ({ id, companyId, value: shortName }));
            setAgOptions(dropdownValues(agData, 'value', undefined, true, 'Select AG'));
            setAgShortname(advisoryGroupMaster?.length === 1 ? advisoryGroupMaster[0].shortName : data?.shortName);
        }
    }, [advisoryGroupMaster]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser.companyId));
            dispatch(getCompanyUsersById(currentUser.companyId));
        }
    }, [isAppLoaded, currentUser, dispatch]);

    const newMeetingObject = {
        shortName: agShortname,
        meetingTime: '09:00',
        meetingTimeZone: TIMEZONE_ABBR.Eastern // set default timezone
    };

    const arr = [
        agSelector && advisoryGroupMaster?.length > 1 ? {
            key: 'id',
            labelName: 'Select AG',
            placeholderName: 'Select AG',
            type: 'options',
            optionValues: agOptions
        } : {
            key: 'shortName',
            labelName: 'AG Short Name',
            placeholderName: 'AG Short Name',
            type: 'text',
            disabled: true,
            required: false
        },
        {
            key: 'title',
            labelName: 'Meeting Title',
            placeholderName: 'Meeting Title',
            type: 'text',
            maxLength: 15,
            valid: VALIDATIONS.String.isTitle
        },
        {
            key: 'meetingDate',
            labelName: 'Meeting Date',
            placeholderName: 'Meeting Date',
            type: 'date',
            minValue: todayStartofDay()
        },
        {
            key: 'meetingTime',
            labelName: 'Meeting Time',
            placeholderName: 'Meeting Time',
            type: 'time',
            cols: 6,
        },
        {
            key: 'meetingTimeZone',
            labelName: 'Meeting Time Zone',
            placeholderName: 'Meeting Time Zone',
            type: 'options',
            optionValues: optionValues(TIMEZONES),
            cols: 6,
        },
        {
            key: 'type',
            labelName: 'Meeting Type',
            placeholderName: 'Select Meeting Type',
            type: 'options',
            optionValues: optionValues(PROGRAM_MEETING_TYPE, true),
        },
        {
            key: 'ownerId',
            labelName: 'Meeting Owner',
            placeholderName: 'Meeting Owner',
            type: 'options',
            optionValues: ownerOptions,
            required: false
        }
    ];

    const handleSubmit = async (formData) => {
        setIsLoading(true);
        const selectAg =  advisoryGroupMaster?.length > 0 && advisoryGroupMaster.find(ag => ag.id === parseInt(formData?.id));
    
        const submitData = {
            agId: agSelector && advisoryGroupMaster?.length === 1 ? advisoryGroupMaster[0].id : agSelector ? selectAg.id : data.id,
            companyId: agSelector && advisoryGroupMaster?.length === 1 ? advisoryGroupMaster[0].companyId : agSelector ? selectAg.companyId : data.companyId,
            title: formData.title,
            meetingDate: combineDateTimeTimezone(formData.meetingDate, formData.meetingTime, formData.meetingTimeZone),
            meetingTimeZone: formData.meetingTimeZone,
            type: formData.type,
            owner: formData.ownerId,
            status: MEETING_STATUS.P,
            lastModifiedUser: currentUser?.id
        }
        
        dispatch(addMeetingDetails(submitData, handleSuccess, handleError));
        setIsLoading(false);
    };

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.AddMeeting, ALERT_TYPE.SUCCESS);
        onCancel();
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>New Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={newMeetingObject}
                        loading={isLoading}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default NewMeetingModal;
