import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getCohorts, archiveCohort, scheduleCohort } from '../../ducks/cohort'
import { AppLoadContext } from '../../components/ui/AppLoadContext'
import DataGrid from "../../components/DataGrid";
import { useNavigate } from "react-router-dom";
import withAuth from "../../components/hoc/withAuth";
import Loader from "../../components/ui/Loader";
import { toDateTime } from "../../utils/utils";
import ActionBar from "../../components/ui/ActionBar";
import ConfirmModal from "../../components/ui/ConfirmModal";
import messages from "../../utils/helper/messages";
import Icon from "../../components/ui/Icon";
import GridButton from "../../components/ui/GridButton";
import { ALERT_TYPE, COHORT_FILE_STATUS, COHORT_TRAINING_FLAG, MESSAGE_LISTEN_TYPE } from "../../constants";
import MergeCohortModal from "./MergeCohortModal";
import NewCohortModal from "./NewCohortModal";
import NewButton from "../../components/ui/NewButton";
import { AlertContext } from "../../context/AlertContext";
import { addMessageHandler, removeMessageHandler } from "../../services/sessionHandler";
import OverflowText from "../../components/ui/OverflowTooltip";

const CohortManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cohortItem, setCohortItem] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [mergeModal, setMergeModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const cohort = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setCohortItem(null);
    }

    const handleConfirm = () => {
        handleArchiveCohort(cohortItem);
        handleConfirmClose();
    }

    const handleMergeModalClose = () => {
        setMergeModal(false);
    }

    const handleAddModalClose = () => {
        setAddModal(false);
    }

    const handleAddCohort = () => {
        handleAddModalClose();
        dispatch(getCohorts(currentUser?.companyId));
    }

    const handleArchiveConfirm = (id) => {
        setConfirmModal(true);
        setCohortItem(id);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        const handler = () => {
            dispatch(getCohorts(currentUser?.companyId));
        };

        addMessageHandler(MESSAGE_LISTEN_TYPE.SCHEDULE_COHORT, handler);
        addMessageHandler(MESSAGE_LISTEN_TYPE.PROCESSED_COHORT_FILE, handler);

        return () => removeMessageHandler(handler);
    }, []);

    const handleArchiveCohort = (id) => {
        dispatch(archiveCohort(id, () => {
            setCohortItem(null);
            showAlert(messages.success.deleteCohort, ALERT_TYPE.SUCCESS);
            dispatch(getCohorts(currentUser?.companyId));
        }, (error) => {
            const archiveError = error.response?.data?.error || error;
            showAlert(`${messages.error.prefix} ${archiveError}`, ALERT_TYPE.ERROR);
        }));
    }

    const handleScheduleCohort = (id) => {
        dispatch(scheduleCohort(id, () => {
            showAlert(messages.success.scheduleCohort, ALERT_TYPE.SUCCESS);
        }));
    }

    const isScheduleDisable = (item) => {
        return item.status !== COHORT_FILE_STATUS.VALIDATED;
    }

    const columns = [
        {
            id: 'name',
            header: 'Cohort Name',
            accessorKey: 'name',
            maxSize: 400,
            sortingFn: 'text'
        },
        {
            id: 'status',
            header: 'Cohort status',
            accessorKey: 'status',
            cell: ({ row }) => (
                <div className="text-decoration-underline text-secondary cursor-pointer" onClick={() => {
                    navigate(`/admin/cohort-management/cohort-upload-overview/${row.original.id}`);
                }}>{row.original.status}</div>
            ),
            sortingFn: 'text'
        },
        {
            id: 'training',
            header: 'Training Cohort',
            accessorKey: 'training',
            size: 120,
            cell: ({ row }) => (
                <div>{COHORT_TRAINING_FLAG[row.original.training]}</div>
            ),
            sortingFn: 'text'
        },
        {
            id: 'fileName',
            header: 'Member List',
            accessorKey: 'fileName',
            sortingFn: 'text',
            cell: ({ row, cell }) => (<OverflowText id={cell.id}>{row.original.fileName}</OverflowText>),
        },
        {
            id: 'memberCount',
            header: 'Size',
            accessorKey: 'memberCount',
            size: 80,
        },
        {
            id: 'marketType',
            header: 'Market Type',
            accessorKey: 'marketType',
            size: 80,
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
            size: 80,
        },
        {
            id: 'dateTime',
            header: 'DateTime',
            accessorKey: 'dateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
            sortingFn: 'datetime',
            size: 150,
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 170,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                        onClick={() => { navigate(`/admin/cohort-management/members/${row.original.id}/${row.original.status}`) }}>
                        <Icon icon="edit" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Merge"
                                disabled={row.original.status === COHORT_FILE_STATUS.ERROR}
                        onClick={() => { setMergeModal(true) }}>
                        <Icon icon="merge" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Schedule"
                        disabled={isScheduleDisable(row.original)}
                        onClick={() => handleScheduleCohort(row.original.id)}>
                        <Icon icon="schedule" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Archive"
                        onClick={() => handleArchiveConfirm(row.original.id)}>
                        <Icon icon="archive" size={16} />
                    </GridButton>
                </>
            )
        },
    ];

    const defaultSort = [{
        id: 'dateTime',
        desc: true
    }];

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" className="me-2" variant="outline-primary"
                    onClick={() => navigate(`/admin/cohort-management/archive`)}>
                    View Archive
                </Button>
                <NewButton onClick={() => setAddModal(true)} />
            </ActionBar>

            <div className="flex-grow-1">
                {cohort === null ? <Loader /> :
                    <DataGrid
                        columns={columns}
                        data={cohort}
                        sort={defaultSort}
                        enableFilters={false}
                    />}
            </div>

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.deleteCohort}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />

            <MergeCohortModal
                show={mergeModal}
                onClose={handleMergeModalClose}
            />

            <NewCohortModal
                show={addModal}
                onCancel={handleAddModalClose}
                onUpload={handleAddCohort}
            />
        </div>
    );
}

export default withAuth(CohortManagement);
