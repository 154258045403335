import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getCohorts, getCohortNotifications } from '../../../ducks/cohort';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import DataGrid from '../../../components/DataGrid';
import withAuth from '../../../components/hoc/withAuth';
import Loader from '../../../components/ui/Loader';
import { isUser, toDateTime } from '../../../utils/utils';
import styles from './MemberManagementHome.module.scss';
import Icon from '../../../components/ui/Icon';
import NavigationBoxDashboard from '../../../components/ui/NavigationBoxDashboard';
import ContentDashboard from '../../../components/ui/ContentDashboard'
import { COHORT_RETENTION_RISK_STATUS_VALUE } from "../../../constants";
import OverflowText from '../../../components/ui/OverflowTooltip';

export const MemberManagementHome = () => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const cohorts = useSelector((state) => state.cohort.cohorts);
    const notifications = useSelector((state) => state.cohort.notifications);
    const currentUser = useSelector((state) => state.user.currentUser);
    const navigate = useNavigate();

    const defaultSort = [
        {
            id: 'dateTime',
            desc: true,
        },
    ];
    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
            dispatch(getCohortNotifications(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    const handleNavigate = (cohortId) => {
        navigate(`/admin/cohort-management/cohort-upload-overview/${cohortId}`);
    };

    const columns = [
        {
            id: 'name',
            header: 'Cohort Name',
            accessorKey: 'name',
            sortingFn: 'text'
        },
        {
            id: 'retentionRiskStatus',
            header: 'Retention Risk Status',
            accessorKey: 'retentionRiskStatus',
            accessorFn: (row) => (COHORT_RETENTION_RISK_STATUS_VALUE[row.retentionRiskStatus] ?? ''),
            maxSize: 100,
        },
        {
            id: 'retentionRiskMonitoringStatus',
            header: 'Retention Risk Monitoring Status',
            accessorKey: 'retentionRiskMonitoringStatus',
            maxSize: 100,
        },
        {
            id: 'status',
            header: 'Cohort Status',
            accessorKey: 'status',
        },
        {
            id: 'dateTime',
            header: 'DateTime',
            accessorKey: 'dateTime',
            sortingFn: 'datetime',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
        },
    ];

    const hideColumn = {
        dateTime: false,
    };

    const columnsNotification = [
        {
            id: 'dateTime',
            header: 'Date/Time',
            accessorKey: 'dateTime',
            sortingFn: 'datetime',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
        },
        {
            id: 'type',
            header: 'Type',
            sortingFn: 'text',
            accessorKey: 'type',
        },
        {
            id: 'message',
            header: 'Message Text',
            accessorKey: 'message',
            cell: ({ row, cell }) => <OverflowText id={cell.id}>{row.original.message}</OverflowText>,
            enableSorting: false,
        },
        ...(!isUser(currentUser) ? [{
            name: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <>
                    <Icon
                        icon="view"
                        size="16px"
                        className={`${styles.viewIcon}`}
                        onClick={() => handleNavigate(row.original.id)}
                    />
                </>
            ),
        }] : [])
    ];

    const NavContent = [
        {
            path: "/member-analytics/population-characterization",
            locationName: "Member Analytics",
            pageName: 'Population Characterization',
            icon: 'population-characterization',
            classname: "gsApple",
            description: "Explore the characteristics of a member cohort."
        },
        {
            path: "/member-analytics/retention-risk",
            locationName: "Member Analytics",
            pageName: 'Retention Risk',
            icon: 'retention-risk',
            classname: "purple",
            description: "View the results of a retention risk assessment and explore the relationship between risk scores and population characteristics."
        }
    ]

    return (
        <Row className="h-100 mb-4">
            <Row className="w-100 mb-4">
                <Col
                    className="d-flex px-4"
                    md={6}>
                    <NavigationBoxDashboard data={NavContent} />
                </Col>
                <Col
                    className="d-flex flex-column px-4"
                    md={6}>
                    <div className={`${styles.tableHeading}`}>
                        Analytics Updates
                    </div>
                    <ContentDashboard />
                </Col>
            </Row>

            <Row className={` ${styles.secondRow} w-100`}>
                <Col
                    className=" d-flex flex-column px-4"
                    md={6}>
                    {cohorts === null ? (
                        <Loader />
                    ) : (
                        <>
                            <div className={`${styles.tableHeader}`}>
                                <div className={`${styles.tableHeading}`}>
                                    Member Files
                                </div>
                                <DataGrid
                                    columns={columns}
                                    data={cohorts}
                                    sort={defaultSort}
                                    rowClick={(data) => handleNavigate(data.id)}
                                    columnDisplay={hideColumn}
                                    enableFilters={false}
                                />
                            </div>
                        </>
                    )}
                </Col>
                <Col
                    className="d-flex flex-column px-4"
                    md={6}>
                    {notifications === null ? (
                        <Loader />
                    ) : (
                        <>
                            <div className={`${styles.tableHeader}`}>
                                <div
                                    className={`d-flex text-center ${styles.tableHeading}`}>
                                    Activity Monitor
                                </div>
                                <DataGrid
                                    columns={columnsNotification}
                                    data={notifications}
                                    sort={defaultSort}
                                    enableFilters={false}
                                />
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Row>
    );
};

export default withAuth(MemberManagementHome);
