import React, { useContext, useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/ui/Loader";
import OverflowText from "../../../components/ui/OverflowTooltip";
import NavigationBoxDashboard from "../../../components/ui/NavigationBoxDashboard";
import DataGrid from "../../../components/DataGrid";
import ActionBar from "../../../components/ui/ActionBar";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import ContentFileModal from "../../../components/ui/ContentFileModal";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import { getContentByApplication } from "../../../ducks/content";
import { toDate } from "../../../utils/utils";
import withAuth from "../../../components/hoc/withAuth";
import styles from "../member-management/MemberManagementHome.module.scss";
import {
    CMS_CONTENT_FORMAT,
    CONTENT_APPLICATION,
    MIME_TYPES
} from "../../../constants";

const RiskExplorer = () => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const contentList = useSelector((state) => state.content.appContent);

    const defaultSort = [
        {
            id: 'createdDateTime',
            desc: true,
        },
    ];

    const NavContent = [
        {
            path: "/member-analytics/retention-risk/risk-explorer/cohort-view",
            locationName: "Member Analytics",
            pageName: 'Cohort View',
            icon: 'cohort-view',
            classname: "gsApple",
            description: "Explore the scores of a single member cohort."
        },
        {
            path: "/member-analytics/retention-risk/risk-explorer/company-view",
            locationName: "Member Analytics",
            pageName: 'Company View',
            icon: 'company-view',
            classname: "purple",
            description: "Explore an aggregated view of retention risk across all scored cohorts for the company."
        }
    ];

    const handleView = (data) => {
        const { id, title, format } = data;
        const type = MIME_TYPES[CMS_CONTENT_FORMAT[format]];
        const content = { id, title, type };
        setShowModal(true);
        setSelectedContent(content);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedContent(null);
    }

    const columns = [
        {
            id: 'createdDateTime',
            header: 'Date Created',
            accessorKey: 'createdDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.createdDateTime)),
            cell: ({ row }) => `${toDate(row.original.createdDateTime)}`,
            size: 150,
            enableColumnFilter: false,
            sortingFn: 'datetime'
        },
        {
            id: 'title',
            header: 'Title',
            accessorKey: 'title',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>{row.original.title}</OverflowText>
            ),
            minSize: 200,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                    onClick={() => handleView(row.original)}>
                    <Icon icon="view" size={14} />
                </GridButton>
            ),
            size: 75,
        },
    ];

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getContentByApplication(CONTENT_APPLICATION.MEMBER_ANALYTICS));
        }
    }, [dispatch, isAppLoaded]);

    return (
        <>
            <Row className="flex-grow-1 gx-5 mb-4">
                <Col
                    md={6}
                    className="d-flex px-4">
                    <Nav className="flex-column flex-grow-1">
                        <NavigationBoxDashboard data={NavContent} />
                    </Nav>
                </Col>
                <Col
                    md={6}
                    className="d-flex flex-column px-4">
                    <div className="border border-1 rounded bg-white p-3 shadow-sm">
                        <p>
                            The <mark className="fw-bold">Risk Explorer</mark> is a tool that allows the user to
                            evaluate the differences in risk scores across
                            different population dimensions including age,
                            geographic location, and preferred language among
                            others. This tool is useful in identifying systemic
                            issues that may be the underlying cause of higher
                            risk scores in certain segments of your member
                            population.
                        </p>
                        <p className="mb-0">
                            The Risk Explorer can be used in two different
                            modes:
                            <ul>
                                <li>
                                    <strong>Cohort View</strong> which allows you to explore the
                                    scores of a single member cohort or
                                    population and
                                </li>
                                <li>
                                    <strong>Company View</strong> which provides you an aggregate
                                    view across all risk scores created for your
                                    organization.
                                </li>
                            </ul>
                            New population dimensions, complex comparative
                            models, and viewing modes will be added to the Risk
                            Explorer over time.
                        </p>
                    </div>
                </Col>
            </Row>
            <div className="flex-grow-1">
                {contentList === null ? (
                    <Loader />
                ) : (
                    <div className="table-alt">
                        <ActionBar title="Risk Explorer Content" />
                        <DataGrid
                            columns={columns}
                            data={contentList}
                            sort={defaultSort}
                            enableFilters={false}
                        />
                    </div>
                )}
            </div>

            {selectedContent && (
                <ContentFileModal
                    show={showModal}
                    contentId={selectedContent.id}
                    title={selectedContent.title}
                    contentType={selectedContent.type}
                    className={styles.viewContent}
                    modalClose={handleClose}
                />
            )}
        </>
    );
}

export default withAuth(RiskExplorer);
