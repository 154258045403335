import React from 'react';
import { Form, Button } from "react-bootstrap";
import CommentItem from './CommentItem';
import Icon from './Icon';
import { toDate } from '../../utils/utils';
import styles from './Comment.module.scss';

const Comment = ({ comments, commentBox, onDelete, onAddComment, showUserName = false, showDate = false }) => {

    return (
        <>
            {!commentBox && <Form.Label>Comments</Form.Label>}
            <div className={styles.commentBox}>
                <ul className={`list-unstyled mb-0 ${styles.commentList}`}>
                    {comments?.length > 0 &&
                        comments?.map((comment) => (
                            <CommentItem
                                key={comment.id}
                                id={comment.id}
                                date={showDate && toDate(comment.memberCommentCreatedDate || comment.recordCreated)}
                                userName={showUserName && `${comment.firstName} ${comment.lastName}`}
                                onDelete={onDelete}>
                                {comment.memberComment || comment.comments}
                            </CommentItem>
                        ))}
                    {!comments ||
                        (comments.length === 0 && (
                            <li className="text-muted px-3 py-2">
                                No comments
                            </li>
                        ))}
                    <Button
                        variant="link"
                        className="text-secondary text-decoration-none d-flex align-items-center"
                        disabled={commentBox}
                        onClick={onAddComment}>
                        <Icon
                            icon="new"
                            size={10}
                            className="me-2"
                        />
                        Add Comment
                    </Button>
                </ul>
            </div>
        </>
    );
};

export default Comment;