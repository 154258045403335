import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import withAuth from '../../../components/hoc/withAuth';
import DataGrid from '../../../components/DataGrid';
import GridButton from '../../../components/ui/GridButton';
import Icon from '../../../components/ui/Icon';
import { toDate, formatPhoneNumber, pickProps, sortingFnDateOnly } from '../../../utils/utils';
import OverflowText from '../../../components/ui/OverflowTooltip';
import EditRecruitingOutreachModal from './EditOutreachModal';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import {
    INITIAL_CALL_STATUS_VALUE,
    ONBOARDING_STATUS, ALERT_TYPE,
    AG_STATUS
} from '../../../constants';
import CommentsOutreachModal from './CommentsOutreachModal';
import {
    getRecruitingOutreachByAgId,
    getAdvisoryGroupMasterData,
    resetRecruitmentOutreachData,
    getMemberComments,
} from '../../../ducks/advisoryGroup';
import ActionBar from '../../../components/ui/ActionBar';
import AgSelector from "../../../components/ui/Selector";
import messages from '../../../utils/helper/messages';
import { AlertContext } from '../../../context/AlertContext';
import Loader from '../../../components/ui/Loader';
import ProxyAttendeeModal from './ProxyAttendeeModal';

const RecruitingOutreach = () => {
    const { agId } = useParams();
    const { showAlert } = useContext(AlertContext);

    const [showOutreachModal, setShowOutreachModal] = useState(false);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [selectedAgId, setSelectedAgId] = useState(0);
    const [agMasterData, setAgMasterData] = useState([]);
    const [currentRowData, setCurrentRowData] = useState(null);
    const [outreachData, setOutreachData] = useState(null);
    const [commentData, setCommentData] = useState(null);
    const [showProxyAttendeeModal, setShowProxyAttendeeModal] = useState(false);

    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();

    const advisoryGroupMasterList = useSelector( (state) => state.advisoryGroup?.advisoryGroupMasterList);
    const currentUser = useSelector((state) => state.user?.currentUser);
    const recruitingOutreachData = useSelector((state) => state.advisoryGroup?.recruitingOutreachData);

    useEffect(() => {
        if (recruitingOutreachData?.length === 0 && selectedAgId !== 0) {
            showAlert(messages.info.agRecruitingAlert, ALERT_TYPE.ERROR);
        }
    }, [recruitingOutreachData]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupMasterData(currentUser?.companyId, AG_STATUS.Active));
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        if (advisoryGroupMasterList !== null) {
            setAgMasterData(advisoryGroupMasterList?.filter((a) => a.status === AG_STATUS.Active));
        }
    }, [advisoryGroupMasterList]);

    useEffect(() => {
        if (advisoryGroupMasterList?.length > 0 && agId) {
            setSelectedAgId(Number(agId));
        }
    }, [advisoryGroupMasterList, recruitingOutreachData]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        selectedAgId === 0 && dispatch(resetRecruitmentOutreachData());
        selectedAgId && dispatch(getRecruitingOutreachByAgId(selectedAgId));
    }, [selectedAgId]);

    const handleEdit = (data) => {
        const requiredData = pickProps(data, ['invitationCall', 'memberCommunicationLastDate', 'onboarding']);
        setOutreachData({
            ...requiredData,
            comment: '',
            memberCommunicationLastDate: data.memberCommunicationLastDate === null ? toDate(new Date()) : toDate(data.memberCommunicationLastDate)
        });
        setCurrentRowData(data);
        setShowOutreachModal(true);
        dispatch(getMemberComments(data.id));
    };

    const handleComment = (data) => {
        setCommentData(pickProps(data, ['id']))
        setCurrentRowData(data);
        setShowCommentsModal(true);
        dispatch(getMemberComments(data.id));
    };

    const handleProxy = (data) => {
        setCurrentRowData(data);
        setShowProxyAttendeeModal(true);
    };

    const rowActions = ({ row }) => {
        return (
            <div className="d-flex">
                <GridButton
                    variant="light"
                    size="sm"
                    className="me-1 my-1"
                    title="Edit"
                    onClick={() => handleEdit(row.original)}>
                    <Icon
                        icon="edit"
                        size={14}
                    />
                </GridButton>
                <GridButton
                    variant="light"
                    size="sm"
                    className="me-1 my-1"
                    title="Proxy"
                    onClick={() => handleProxy(row.original)}>
                    <Icon
                        icon="proxy"
                        size={14}
                    />
                </GridButton>
                <GridButton
                    variant="light"
                    size="sm"
                    className="me-1 my-1"
                    title="Comments"
                    onClick={() => handleComment(row.original)}>
                    <Icon
                        icon="comments"
                        size={14}
                    />
                </GridButton>
            </div>
        );
    };

    const onEdit = () => dispatch(getRecruitingOutreachByAgId(selectedAgId));

    const columns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.firstName}
                </OverflowText>
            ),
            maxSize: 300,
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            cell: ({ row }) => row.original.lastName,
            size: 150,
        },
        {
            id: 'dateOfBirth',
            header: 'DOB',
            accessorFn: (row) => toDate(row.dateOfBirth),
            size: 150,
            sortingFn: sortingFnDateOnly,
            sortType: 'date',
            filterFn: 'equalsString'
        },
        {
            id: 'city',
            header: 'City',
            accessorKey: 'city',
            sortType: 'text',
            cell: ({ row }) => row.original.city,
            size: 150,
        },
        {
            id: 'primaryLanguage',
            header: 'Language',
            accessorKey: 'primaryLanguage',
            sortType: 'text',
            cell: ({ row }) => row.original.primaryLanguage,
            size: 150,
        },
        {
            id: 'invitationCall',
            header: 'Initial Call Status',
            accessorKey: 'invitationCall',
            sortType: 'text',
            accessorFn: (row) => row.invitationCall === null ? 'null' :`${INITIAL_CALL_STATUS_VALUE[row.invitationCall]}`,
            cell: ({ row }) => INITIAL_CALL_STATUS_VALUE[row.original.invitationCall],
            size: 150,
        },
        {
            id: 'onboarding',
            header: 'Onboarding Status',
            accessorKey: 'onboarding',
            sortType: 'text',
            accessorFn: (row) => row.onboarding === null ? 'null' :`${ONBOARDING_STATUS[row.onboarding]}`,
            cell: ({ row }) => ONBOARDING_STATUS[row.original.onboarding],
            size: 150,
        },
        {
            id: 'lastContactDate',
            header: 'Last Contact Date',
            accessorFn: (row) => new Date(row.memberCommunicationLastDate),
            cell: ({ row }) => row.original.memberCommunicationLastDate === null ? toDate(new Date()) : `${toDate(row.original.memberCommunicationLastDate)}`,
            size: 150,
            enableColumnFilter: false,
        },
        {
            id: 'phone',
            header: 'Phone',
            accessorKey: 'phone',
            cell: ({ row }) => (
                <div className="text-nowrap">
                    {formatPhoneNumber(row.original.phone)}
                </div>
            ),
            size: 150,
            enableColumnFilter: false,
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: rowActions,
            size: 90,
        },
    ];

    const handleSelectChange = (e) => {
        setSelectedAgId(Number(e.target.value));
    }

    const handleEditModalClose = () => {
        setShowOutreachModal(false);
        setCurrentRowData(null);
        setOutreachData(null);
        setCommentData(null);
    };
    const handleCommentsModalClose = () => {
        setShowCommentsModal(false);
        setCurrentRowData(null);
    };

    const handleProxyModalClose = () => {
        setCurrentRowData(null);
        setShowProxyAttendeeModal(false);
    };

    return (
        <>
            <ActionBar back={true}>
                <AgSelector
                    items={agMasterData}
                    param="shortName"
                    value={selectedAgId}
                    placeholder="Select Advisory Group"
                    noRecordMessage="No advisory groups"
                    onSelectChange={handleSelectChange}
                    wrapperStyle="w-auto"
                />
            </ActionBar>
            {recruitingOutreachData === null && selectedAgId !== 0 ? (
                <Loader />
            ) : (
                <DataGrid
                    columns={columns}
                    data={recruitingOutreachData || []}
                />
            )}
            <EditRecruitingOutreachModal
                show={showOutreachModal}
                onCancel={handleEditModalClose}
                data={outreachData}
                agId={selectedAgId}
                onEdit={onEdit}
                rowData={{ id: currentRowData?.id, memberStatus: currentRowData?.memberStatus, memberStartDate: currentRowData?.memberStartDate }}
            />
            <CommentsOutreachModal
                show={showCommentsModal}
                onCancel={handleCommentsModalClose}
                data={commentData}
                agId={selectedAgId}
            />
            {showProxyAttendeeModal && (
                <ProxyAttendeeModal
                    data={currentRowData}
                    show={showProxyAttendeeModal}
                    onCancel={handleProxyModalClose}
                />
            )}
        </>
    );
};

export default withAuth(RecruitingOutreach);
