import messages from "../utils/helper/messages";

function PasswordChangedRedirect() {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center p-3 p-md-5">
                <h1 className="fw-bold">{messages.success.updatePassword}</h1>
                <div className="h5">Please signin again.</div>
            </div>
        </div>
    );
}

export default PasswordChangedRedirect;
