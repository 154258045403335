import { PhoneNumberUtil } from 'google-libphonenumber';
import {
    EXPORT_RETENTION_RISK_FORMAT,
    MIME_TYPES,
    TIMEZONES,
    USER_ROLE,
    VALIDATIONS,
    VALUE,
} from '../constants';
import menuData from '../components/ui/menuData';
import pageString from "../utils/helper/pageString";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import omit from "lodash/omit";
import DOMPurify from 'dompurify';
DOMPurify.setConfig({
    USE_PROFILES: { html: true },
    WHOLE_DOCUMENT: true
});

const moment = require('moment-timezone');

export function parsePhoneNumber(phoneNumber) {
    if (isDevMode()) {
        return phoneNumber;
    }

    const phoneUtil = PhoneNumberUtil.getInstance();

    let number = phoneUtil.parse(phoneNumber, 'US');
    let nationalNumber = number.getNationalNumber();

    return nationalNumber;
}

export function formatPhoneNumber(phoneNumber) {
    // e.g., 5551234567 -> 555-123-4567
    try {
        let phoneNumberWithDashes = phoneNumber.replace(
            /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
            '$1$2$3-$4$5$6-$7$8$9$10'
        );
        return phoneNumberWithDashes;
    } catch {
        return phoneNumber;
    }
}

export function checkObjectsEqual(object1, object2, excludedKeys = []) {
    if (!object1 || !object2)
        return false;

    const keysA = Object.keys(object1).filter(key => !excludedKeys.includes(key));
    const keysB = Object.keys(object2).filter(key => !excludedKeys.includes(key));

    if (keysA.length !== keysB.length) {
        return false;
    }
    for (let key of keysA) {
        if (!keysB.includes(key) || !isEqual(object1[key], object2[key])) {
            return false;
        }
    }
    return true;
}

export function isPhoneNumberValid(
    phoneNumber,
    useStrictPhoneValidation = true
) {
    if (!phoneNumber) {
        return false
    }

    // strip all chars except numbers
    let cleanedNumber = phoneNumber.replace(/[^\d]/g, '');
    let basicValidation = VALIDATIONS.Number.isPhone.test;
    let basicValidationTest = basicValidation.test(cleanedNumber);

    if (isDevMode() || useStrictPhoneValidation === false) {
        return basicValidationTest
    }

    let valid = false;
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        valid = phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(phoneNumber.toString(), 'US'),
            'US'
        );
    } catch (e) {
        valid = false;
    }

    // Basic validation is added because the google-libphonenumber package allows letters
    return basicValidationTest && valid;
}

export function isEmailValid(email) {
    let valid = false;
    try {
        const regularExpression = RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        valid = regularExpression.test(email);
    } catch (e) {
        valid = false;
    }

    return valid;
}

export function isObjectEmpty(obj) {
    if (!obj) {
        return true;
    }
    return !Object.keys(obj).length;
}

export function isArrayUniqueValues(arr) {
    return new Set(arr).size === arr.length;
}

export function isDevMode() {
    return process.env.NODE_ENV === 'development';
}

export function isUserAdmin(user) {
    return (
        user?.roleId === USER_ROLE.SYSTEM_ADMIN ||
        user?.roleId === USER_ROLE.CLIENT_ADMIN
    );
}

export function isUserSysAdmin(user) {
    return user?.roleId === USER_ROLE.SYSTEM_ADMIN;
}

export function isUserClientAdmin(user) {
    return user?.roleId === USER_ROLE.CLIENT_ADMIN;
}

export function isUser(user) {
    return user?.roleId === USER_ROLE.USER;
}

export function isDevTestUserId(userId) {
    if (!userId) {
        return false;
    }

    return userId < 0;
}

export function toDateTimeLocal(dateString) {
    return dateString ? moment(dateString).local().format('YYYY-MM-DDTHH:mm:ss') : '';
}

export function toDateTime(dateString, dayFirst = false) {
    if (dayFirst) {
        return dateString ? moment(dateString).format('DD/MM/YYYY HH:mm:ss') : '';
    }
    else {
        return dateString ? moment(dateString).format('MM/DD/YYYY HH:mm:ss') : '';
    }
}

export function toDate(dateString) {
    return dateString ? moment(dateString).format('MM/DD/yyyy') : '';
}

export function toTimezoneFormat(dateString, format, timezone = 'US/Eastern') {
    if (!dateString) return '';
    const timezoneDate = moment.utc(dateString).tz(timezone);
    return timezoneDate.format(format);
}

export function combineDateTimeTimezone(date, time, timezone) {
    const dateTime = `${date} ${time}`;
    return moment.tz(dateTime, TIMEZONES[timezone]).utc().format();
}

export function toFormDateTime(dateString) {
    return dateString ? moment(dateString).format('YYYY-MM-DD') : '';
}

export function greaterThanFormDateTime(dateString) {
    return dateString ? moment(dateString).add(1, 'day').format('YYYY-MM-DD') : '';
}

export function lessThanFormDateTime(dateString) {
    return dateString ? moment(dateString).subtract(1, 'day').format('YYYY-MM-DD') : '';
}

export function toDateWithMonth(dateString) {
    return dateString ? moment(dateString).format('MMMM, DD yyyy') : '';
}

export function checkWhiteSpace(val) {
    return /^\s+|\s{2,}|\s+$/.test(val);
}

export function checkValidPositiveInteger(val) {
    return /^(0|[1-9]\d*)$/.test(val);
}

export function checkValid(val, reg) {
    return {
        valid: reg.test.test(val),
        error: reg.msg
    }
}

export function optionValues(obj, includeBlank = false, placeholder = 'Select') {
    let optionArr = [];
    if (includeBlank) {
        optionArr.push({ id: '', value: placeholder });
    }
    for (const [key, value] of Object.entries(obj)) {
        optionArr.push({ id: key, value: value });
    }
    return optionArr;
}

export function radioOptionValues(obj, keyAsLabel = false) {
    let optionArr = [];
    for (const [key, value] of Object.entries(obj)) {
        optionArr.push({ id: key, label: keyAsLabel ? key : value, value: value });
    }
    return optionArr;
}

export function dropdownValues(arr, valueKey, idKey = 'id', includeBlank = false, placeholder = 'Select') {
    let optionArr = [];
    if (includeBlank) {
        optionArr.push({ id: '', value: placeholder });
    }
    for (let option of arr) {
        optionArr.push({ id: option[idKey], value: option[valueKey] });
    }
    return optionArr;
}


export function getPageSlug(url) {
    if (!url) return;
    const pathArr = url.split('/');
    return pathArr[pathArr.length - 1];
}

export function getAllPathComponents(url) {
    if (!url) return;
    const pathArr = url.split('/');
    return pathArr;
}

export function getRolesBySlug(
    slug,
    data = menuData,
    defaultRoles = Object.values(USER_ROLE)
) {
    for (let item of data) {
        const parentRoles = item.roles || defaultRoles;
        if (item.to === slug) {
            return item.roles || parentRoles;
        } else if (item.children) {
            const itemRoles = getRolesBySlug(slug, item.children, item.roles);
            if (itemRoles?.length > 0) {
                return itemRoles;
            }
        }
    }
    return null;
}

export function getInheritedRoles(matches) {
    let pathArr = [...matches].reverse();
    for (let item of pathArr) {
        if (item.handle?.roles) {
            return item.handle?.roles;
        }
    }

    return null;
}

export function getFileExtension(fileName) {
    return fileName?.split('.').pop();
}

export function getFileContentType(fileName) {
    if (!fileName) return;
    const fileExt = getFileExtension(fileName);
    return MIME_TYPES[fileExt.toUpperCase()];
}

export function getPageNameFromLocationPathName(path) {
    switch (true) {
        case path.includes(pageString.route.cohortManagement):
            return pageString.navigation.cohortManagement;
        case path.includes(pageString.route.contentManagement):
            return pageString.navigation.contentManagement;
        case path.includes(pageString.route.retentionRisk):
            return pageString.navigation.retentionRisk;
        case path.includes(pageString.route.marketAssessment):
            return pageString.navigation.marketAssessment;
        case path.includes(pageString.route.planDesign):
            return pageString.navigation.planDesign;
        case path.includes(pageString.route.advisoryGroupManagement):
            return pageString.navigation.advisoryGroupManagement;
        default:
            return pageString.navigation.home;

    }
}

export function isValidName(name) {
    if (!name) return false;
    const isEmptyString = name === '';
    const hasWhiteSpace = checkWhiteSpace(name);

    return !isEmptyString && !hasWhiteSpace;
}

export function downloadFile({ data, fileName, fileType }) {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
}

export function exportToXLS(data, fileName) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const exelData = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    downloadFile({
        data: exelData,
        fileName: `${fileName}.xlsx`,
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
}

export function exportToCSV(data, fileName) {
    const ws = XLSX.utils.json_to_sheet(data);
    const csvdata = XLSX.utils.sheet_to_csv(ws);

    downloadFile({
        data: csvdata,
        fileName: `${fileName}.csv`,
        fileType: 'text/csv',
    });
}

export function exportToPDF(columns, rows, fileName) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    let content = {
        startY: 50,
        head: columns,
        body: rows,
        styles: {
            font: "Arial",
            cellPadding: 3.5,
            lineWidth: 0.5,
            minCellWidth: 40,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0]
        }
    };
    doc.autoTable(content);
    doc.save(fileName);
}

export function exportData(data, rows, columns, fileName, type) {
    switch (EXPORT_RETENTION_RISK_FORMAT[type]) {
        case EXPORT_RETENTION_RISK_FORMAT.C: {
            exportToCSV(data, fileName);
            break;
        }
        case EXPORT_RETENTION_RISK_FORMAT.P: {
            exportToPDF(columns, rows, fileName);
            break;
        }
        case EXPORT_RETENTION_RISK_FORMAT.X: {
            exportToXLS(data, fileName);
            break;
        }
    }
}

export function sortByString(arr, param) {
    arr.sort((a, b) => {
        const nameA = a[param].toUpperCase(); // ignore upper and lowercase
        const nameB = b[param].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return arr;
}

export function sortingFnText(rowA, rowB, columnId) {
    const nameA = rowA.original[columnId];
    const nameB = rowB.original[columnId];
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // names must be equal
    return 0;
}

export function sortByDate(arr, param) {
    arr.sort((a, b) => {
        const nameA = moment(a[param]); // ignore upper and lowercase
        const nameB = moment(b[param]); // ignore upper and lowercase
        if (nameB.isBefore(nameA)) {
            return -1;
        }
        if (nameA.isBefore(nameB)) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return arr;
}

export function sortingFnDateOnly(rowA, rowB, columnId) {
    const dateA = moment(rowA.original[columnId]);
    const dateB = moment(rowB.original[columnId]);
    if (dateB.isBefore(dateA)) {
        return 1
    }
    if (dateA.isBefore(dateB)) {
        return -1
    }

    return 0;
}

export function isDateObject(date) {
    return (typeof date === "object" && date instanceof Date);
}


export function dateObjectEquals(row, columnId, filterValue) {
    if (isDateObject(filterValue)) {
        return (new Date(row.original[columnId]).getTime() === filterValue.getTime())
    }
    return false
}

export function getReportFromPayload(payloadValue, reportname) {
    return payloadValue.find(item => {
        return (item.name === reportname)
    });
}

export function isBeforeNow(dateTime) {
    const momentDate = moment(dateTime);
    return (momentDate.isBefore(moment()));
}

export function isBeforeToday(dateTime) {
    const momentDate = moment(dateTime);
    return (momentDate.isBefore(moment(), 'day'));
}

export function isSameOrBeforeToday(dateTime) {
    const momentDate = moment(dateTime);
    return (momentDate.isSameOrBefore(moment(), 'day'));
}

export function isAfterToday(dateTime) {
    const momentDate = moment(dateTime);
    return (momentDate.isAfter(moment(), 'day'));
}

export const todayEndofDay = () => moment().add(1, 'day').startOf('day');

export const todayStartofDay = () => moment().subtract(1, 'day').startOf('day');

export const dayOfWeek = (date) => {
    const momentDate = moment(date);
    return momentDate.format('ddd');
}

export const daysLeftForFutureDate = (date) => {
    const today = moment();
    return moment(date).diff(today, 'days');
}

export function checkValuesEqual(value1, value2) {
    return isEqual(value1, value2)
}

export function pickProps(obj, props) {
    return pick(obj, props);
}

export function omitProps(obj, props) {
    return omit(obj, props);
}

export function getFormattedValue(value, type, operation = null) {
    if (type === 'date') {
        if (operation === VALUE.Min) {
            return greaterThanFormDateTime(value)
        } else if (operation === VALUE.Max) {
            return lessThanFormDateTime(value)
        }
        return toFormDateTime(value)
    } else if (type === 'datetime-local') {
        return toDateTimeLocal(value)
    } else if (checkWhiteSpace(value) && value.toString().trim() === '') { // Fix for: input value = multiple spaces
        return ''
    } else {
        return value ?? ''
    }
}


export function dataURLtoFile(dataurl, filename) {
    if (dataurl && typeof dataurl === 'string') {
        var arr = dataurl.split(','),
            mime = arr.length > 0 ? (arr[0].match(/:(.*?);/) ? arr[0].match(/:(.*?);/)[1] : null) : null,
            lastItem = arr[arr.length - 1],
            bstr = lastItem && /^[A-Za-z0-9+/=]+$/.test(lastItem) ? atob(arr[arr.length - 1]) : null,
            n = bstr?.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr?.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    return dataurl;
}

export function convertToSensivityData(reskScoreData) {
    const arr = [];
    for (let element of reskScoreData) {
        if (element.sensitivityName !== null) {
            const index = arr.findIndex(s => s.id === element.id);
            if (index === -1) {
                arr.push({ ...element, [element.sensitivityName]: element.sensitivityValue, sensitivityName: [element.sensitivityName] });
            }
            else {
                arr[index] = { ...arr[index], [element.sensitivityName]: element.sensitivityValue, sensitivityName: [...arr[index].sensitivityName, element.sensitivityName] };
            }
        }
        else {
            arr.push(element);
        }
    }
    return arr;
}

export function getRiskScoreColor(value) {
    switch (true) {
        case (value < 0.25):
            return ' bg-green';
        case (value >= 0.25 && value < 0.75):
            return ' bg-yellow';
        case (value >= 0.75):
            return ' bg-red';
        default:
            return '';
    }
}

export function toDecimal(number, decimals = 2) {
    if ([undefined, null].includes(number)) {
        return null
    }
    return Number.parseFloat(number).toFixed(decimals);
}

export function readFileAsTextAndSanitize(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(DOMPurify.sanitize(reader.result));
        reader.onerror = reject;
        reader.readAsText(file);
    });
}

export const getNameList = (data) => {
    const names = {};
    data?.length > 0 && data.forEach((item) => {
        const fullName = `${item.firstName} ${item.lastName}`;
        names[item.id] = fullName;
    });
    return names;
};

export const getFullName = (firstName, lastName) => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if (firstName) {
        return firstName;
    } else if (lastName) {
        return lastName;
    } else {
        return '';
    }
};

export const compareArray = (updatedArrayData, initialArrayData) => {
    if (updatedArrayData.length !== initialArrayData.length) return false;

    return updatedArrayData.every((value, index) => value === initialArrayData[index]);
};

export const calculateAttendancePercentage = (attended, total) => {
    return toDecimal(isNaN((attended / total) * 100) ? 0 : ((attended / total) * 100));
}

export function encodeSpecialChars(str) {
    return encodeURIComponent(str)
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/!/g, '%21')
}
