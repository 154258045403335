import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import { ALIGN, POSITIONS, RECORD_STATUS_ABBR, ALERT_TYPE } from "../../../../constants";
import styles from './MemberDetailsModal.module.scss';
import messages from '../../../../utils/helper/messages';
import { AlertContext } from '../../../../context/AlertContext';
import { isObjectEmpty } from "../../../../utils/utils";
import EditRosterDetailsModal from "./EditRosterDetailsModal";
import Loader from "../../../../components/ui/Loader";
import CommentList from "../../../../components/ui/CommentList";
import { getMemberComments, insertMemberComment } from "../../../../ducks/advisoryGroup";


const MemberDetailsModal = ({ show, onCancel, data }) => {
    const { showAlert } = useContext(AlertContext);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [memberDetails, setMemberDetails] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [commentDetails, setCommentDetails] = useState(null);
    const [isCommentLoading, setIsCommentLoading] = useState(false);
    const [memberComments, setMemberComments] = useState(null);

    const currentUser = useSelector((state) => state.user?.currentUser);
    const comments = useSelector((state) => state.advisoryGroup?.memberCommentList);

    const arr = [
        { key: 'firstName', labelName: 'First Name', type: 'text', required: false},
        { key: 'lastName', labelName: 'Last Name', type: 'text', required: false},
        { key: 'memberStartDate', labelName: 'Start Date', type: 'date', required: false},
        { key: 'memberCommunicationLastDate', labelName: 'Communication Last Date', type: 'date', required: false},
        { key: 'address', labelName: 'Address', placeholderName: 'Address', type: 'text', required: false },
        {
            key: 'phone',
            labelName: 'Phone',
            placeholderName: 'Phone',
            type: 'tel',
            maxLength: 10,
            required: false
        },
        {
            key: 'email',
            labelName: 'Email',
            placeholderName: 'Email',
            type: 'email',
            required: false
        },
    ];

    useEffect(() => {
        if (data !== null) {
            setMemberDetails({ ...data, address: `${data.addressLine1} ${data.addressLine2 ?? ''}`.trim() });
        }
    }, [data, show]);

    useEffect(() => {
        if (comments) {
            let commentList = [];
            for (let comment of comments) {
                commentList.push({
                    id: comment.id,
                    firstName: comment.firstName,
                    lastName: comment.lastName,
                    recordCreated: comment.memberCommentCreatedDate,
                    comment: comment.memberComment
                });
            }
            setMemberComments(commentList);
        }
    }, [comments]);

    const onHideCallback = () => {
        onCancel();
        setMemberDetails(null);
        setIsEdit(false);
    };

    const handleSubmit = () => {

        if (!commentDetails.comment) {
            return
        }
        setIsCommentLoading(true);
        const commentData = {
            agId: data.aGId,
            status: RECORD_STATUS_ABBR.Operational,
            companyId: currentUser.companyId,
            agMemberId: data.id,
            userId: currentUser?.id,
            comment: commentDetails.comment,
        };
        dispatch(insertMemberComment(commentData, () => {
            setIsCommentLoading(false);
            showAlert(messages.success.addRosterComment, ALERT_TYPE.SUCCESS);
            dispatch(getMemberComments(data.id));
            handleCommentCancel();
        }, (error) => {
            setIsCommentLoading(false);
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }));
        setLoading(false);
    };

    const handleInputChange = (name, value) => {
        handleCommentDetailsChange({ [name]: value });
    };

    const handleCommentDetailsChange = (update) => {
        setCommentDetails((details) => ({ ...details, ...update }));
    };

    const handleCommentCancel = () => {
        let commentDetailsCopy = {...commentDetails};
        delete commentDetailsCopy.comment;
        setCommentDetails(commentDetailsCopy);
    }

    const inputChange = (name, value) => {
        handleRosterDetailsChange({ [name]: value });
    };

    const handleRosterDetailsChange = (update) => setMemberDetails((details) => ({ ...details, ...update }));

    const isValidRosterData = () => !isObjectEmpty(memberDetails);

    const handleEdit = () => {
        onHideCallback();
        setIsEdit(true);
    };

    const handleMemberModalClose = () => {
        setIsEdit(false);
        onCancel();
    }

    return (
        <>
            <Modal
                show={show}
                fullscreen={false}
                backdrop="static"
                onHide={onHideCallback}
                size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Member Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`text-${ALIGN.RIGHT} mb-4`}>
                        <Button
                            type="submit"
                            className="me-2"
                            onClick={handleEdit}>
                            Edit
                        </Button>
                    </div>
                    <div className="px-xl-3 py-xl-2">
                        <SharedForm
                            cols={12}
                            array={arr}
                            modelObj={memberDetails}
                            onSubmit={handleSubmit}
                            onCancel={handleEdit}
                            actionBarPosition={POSITIONS.TOP}
                            actionBarAlign={ALIGN.RIGHT}
                            loading={loading}
                            disabled={!isValidRosterData()}
                            onInputChanged={inputChange}
                            viewOnly={true}
                        />
                        <div className="position-relative">
                                {isCommentLoading &&
                                    <Loader />
                                }
                                <CommentList
                                    comments={memberComments}
                                    title={
                                        <h3
                                            className={`mb-0 lh-sm ${styles.title}`}>
                                            Comments
                                        </h3>
                                    }
                                    maxLength={255}
                                    customListStyle={`flex-grow-1 ${styles.commentsContainer}`}
                                    onCommentChange={handleInputChange}
                                    onCommentSave={handleSubmit}
                                    onCommentCancel={handleCommentCancel}
                                />
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
            {isEdit && (
                <EditRosterDetailsModal
                    show={isEdit}
                    data={data}
                    onCancel={handleMemberModalClose}
                />
            )}
        </>
    );
}

export default MemberDetailsModal;
