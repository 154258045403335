import { Suspense, lazy } from 'react';

const PdfLazy = lazy(() => import('./PdfLazy'));

const PdfViewer = (props) => {

    return (
        <Suspense fallback={`Loading...`}>
            <PdfLazy {...props} />
        </Suspense>
    );
};

export default PdfViewer;
