import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
    ALIGN,
    POSITIONS,
    INITIAL_CALL_STATUS_VALUE,
    ONBOARDING_STATUS,
    RECORD_STATUS_ABBR,
    ALERT_TYPE,
    INITIAL_CALL_STATUS,
    EMPTY_OPTION,
    AG_RECRUITMENT_MEMBER_STATUS,
    ONBOARDING_STATUS_VALUE, VALIDATIONS
} from "../../../constants";
import SharedForm from '../../../components/ui/Form';
import { isObjectEmpty, optionValues, toDate, todayEndofDay } from '../../../utils/utils';
import {
    updateRecruitingOutreach,
    archiveMemberComment,
    getMemberComments,
    insertMemberComment,
    resetMemberComments
} from '../../../ducks/advisoryGroup';
import messages from '../../../utils/helper/messages';
import { AlertContext } from '../../../context/AlertContext';
import Comment from '../../../components/ui/Comment';
import Loader from '../../../components/ui/Loader';

const RecruitingOutreachModal = ({ data, show, onCancel, agId, onEdit, rowData }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [outreachDetails, setOutreachDetails] = useState(null);

    const initialCallStatus = optionValues(INITIAL_CALL_STATUS_VALUE, true);
    const onboardingStatus = outreachDetails?.invitationCall === INITIAL_CALL_STATUS.Declined ? optionValues(EMPTY_OPTION) : optionValues(ONBOARDING_STATUS, true);

    const comments = useSelector(state => state.advisoryGroup?.memberCommentList);
    const currentUser = useSelector(state => state.user?.currentUser);

    useEffect(() => {
        if (data !== null) {
            setOutreachDetails(data);
        }
    }, [data]);

    const arr = [
        {
            key: 'invitationCall',
            labelName: 'Initial Call Status',
            placeholderName: 'Initial Call Status',
            type: 'options',
            optionValues: initialCallStatus,
            required: false,
        },
        {
            key: 'onboarding',
            labelName: 'Onboarding Status',
            placeholderName: 'Onboarding Status',
            type: 'options',
            optionValues: onboardingStatus,
            required: false,
            disabled: true,
            conditional: true,
            dependsOn: 'invitationCall',
            dependsValue: [INITIAL_CALL_STATUS.Acceptance, INITIAL_CALL_STATUS.Other, INITIAL_CALL_STATUS.Not_Available]
        },
        {
            key: 'memberCommunicationLastDate',
            labelName: 'Last Communication Date',
            type: 'date',
            required: false,
            maxValue: todayEndofDay()
        },
        {
            key: 'comment',
            labelName: showCommentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !showCommentBox,
            maxLength: 255,
            valid: VALIDATIONS.String.noSpecialChars,
        },
    ];

    const handleCancel = () => {
        onCancel();
        setShowCommentBox(false);
        setLoading(false);
        setOutreachDetails(null);
        dispatch(resetMemberComments());
    };

    const checkOutreachDataEquals = (prevData, currData) => {
        return (
            prevData.invitationCall === currData.invitationCall && prevData.memberCommunicationLastDate === currData.memberCommunicationLastDate && prevData.onboarding === currData.onboarding
        );
    };

    const handleDeleteSuccess = () => {
        dispatch(getMemberComments(rowData.id));
        showAlert(messages.success.deleteOutreachComment, ALERT_TYPE.SUCCESS);
        setLoading(false);
    }

    const handleError = (error) => {
        setLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleEditSuccess = () => {
        onEdit();
        setLoading(false);
        resetFormState();
        showAlert(messages.success.updateRecruitingOutreach, ALERT_TYPE.SUCCESS);
    };

    const handleCommentSuccess = () => {
        setLoading(false);
        resetFormState();
        showAlert(messages.success.addOutreachComment, ALERT_TYPE.SUCCESS);
        dispatch(resetMemberComments());
    };

    const submit = (formData) => {

        if (!formData) return;

        const prevOutreachData = {
            invitationCall: data.invitationCall,
            memberCommunicationLastDate: data.memberCommunicationLastDate,
            onboarding: data.onboarding
        };
        const updateOutreachData = {
            id: rowData.id,
            invitationCall: formData.invitationCall ? formData.invitationCall : null,
            memberCommunicationLastDate: formData.memberCommunicationLastDate ? toDate(formData.memberCommunicationLastDate) : null,
            onboarding: formData.invitationCall === INITIAL_CALL_STATUS.Declined ? null : (formData.onboarding || null),
            lastUsertoUpdateRecord: currentUser.id,
            memberStatus: formData.onboarding === ONBOARDING_STATUS_VALUE.Complete ? AG_RECRUITMENT_MEMBER_STATUS.Enrolled : rowData.memberStatus,
            memberStartDate: formData.onboarding === ONBOARDING_STATUS_VALUE.Complete ? new Date() : rowData.memberStartDate
        };
        const isOutreachDataEqual = checkOutreachDataEquals(prevOutreachData, updateOutreachData);

        if (!isOutreachDataEqual) {
            dispatch(updateRecruitingOutreach(updateOutreachData, handleEditSuccess, handleError));
            setLoading(true);
        }

        const commentData = {
            agId: agId,
            status: RECORD_STATUS_ABBR.Operational,
            companyId: currentUser.companyId,
            agMemberId: rowData.id,
            userId: currentUser.id,
            comment: formData.comment,
        };

        if (formData.comment) {
            dispatch(insertMemberComment(commentData, handleCommentSuccess, handleError));
            setLoading(true);
        }
    };

    const resetFormState = () => {
        onCancel();
        setShowCommentBox(false);
        setOutreachDetails(null);
    }

    const handleDelete = (id) => {
        const deleteCommentData = {
            id: id,
            status: RECORD_STATUS_ABBR.Archived,
        };
        dispatch(archiveMemberComment(deleteCommentData, handleDeleteSuccess, handleError));
    };

    const handleOutreachDetailsChange = (update) => setOutreachDetails((details) => ({ ...details, ...update }));

    const inputChange = (name, value) => {
        handleOutreachDetailsChange({ [name]: value });
    };

    const isValidOutreachData = () => !isObjectEmpty(outreachDetails);

    const handleAddComment = () => setShowCommentBox(true);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Recruiting Outreach</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        onSubmit={submit}
                        onCancel={handleCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={'Save'}
                        onInputChanged={inputChange}
                        loading={loading}
                        disabled={!isValidOutreachData()}
                    />
                    {comments === null ? (
                        <Loader size={2} />
                    ) : (
                        <Comment
                            comments={comments}
                            onAddComment={handleAddComment}
                            commentBox={showCommentBox}
                            onDelete={handleDelete}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RecruitingOutreachModal;
