import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import { ALIGN, POSITIONS, RECORD_STATUS_ABBR, ALERT_TYPE, VALIDATIONS } from "../../../../constants";
import { insertMemberComment, resetMemberComments } from '../../../../ducks/advisoryGroup';
import messages from '../../../../utils/helper/messages';
import { AlertContext } from '../../../../context/AlertContext';
import Comment from '../../../../../src/components/ui/Comment';
import Loader from "../../../../components/ui/Loader";

const CommentModal = ({ show, onCancel, data }) => {

    const { showAlert } = useContext(AlertContext);
    const dispatch = useDispatch();

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [commentDetails, setCommentDetails] = useState(null);
    

    const currentUser = useSelector((state) => state.user?.currentUser);
    const comments = useSelector((state) => state.advisoryGroup?.memberCommentList);

    const arr = [
        {
            key: 'comment',
            labelName: showCommentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !showCommentBox,
            maxLength: 255,
            valid: VALIDATIONS.String.noSpecialChars,
        }
    ];

    const onHideCallback = () => {
        onCancel();
        setShowCommentBox(false);
        dispatch(resetMemberComments());
    };

    const handleSubmit = (formData) => {
        const commentData = {
            agId: data.aGId,
            status: RECORD_STATUS_ABBR.Operational,
            companyId: currentUser.companyId,
            agMemberId: data.id,
            userId: currentUser.id,
            comment: formData.comment,
        };
        if (formData.comment) {
            setLoading(true);
            dispatch(insertMemberComment(commentData, handleCommentSuccess));
        }
        onCancel();
        setCommentDetails(null);
        setLoading(false);
        setShowCommentBox(false);
    };

    const handleCommentSuccess = () => {
        setLoading(false);
        showAlert(messages.success.addRosterComment, ALERT_TYPE.SUCCESS);
        dispatch(resetMemberComments());
    };

    const handleAddComment = () => setShowCommentBox(true);

    const handleCommentDetailsChange = (update) => setCommentDetails((details) => ({ ...details, ...update }));

    const inputChange = (name, value) => handleCommentDetailsChange({ [name]: value });

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>View Comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={'Submit'}
                        loading={loading}
                        disabled={!showCommentBox || commentDetails?.comment === ''}
                        onInputChanged={inputChange}
                    />
                    {comments === null ? (
                        <Loader size={2} />
                    ) : (
                        <Comment
                            comments={comments}
                            showUserName={true}
                            showDate={true}
                            onAddComment={handleAddComment}
                            commentBox={showCommentBox}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CommentModal;
